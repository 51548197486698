import {eventConstants, globalConstants} from "../constants";
import {cloneDeep} from "lodash";

const initialState = {
    shown: false,
    launched: false,
    inputResult: "",
    enableButton: false,
    showPrinting: false,
    tickets: [],
    jobs: []
};

const popupNameEntryState = (state = initialState, action) => {
    switch (action.type) {
        case eventConstants.DONE_SHOWING_PRINT_SCREEN:
            reducerName(state, action);

            return {
                ...state,
                 shown: false,
                showPrinting: false,
                enableButton: false

            };

        case eventConstants.SUCCESS_PRINT:
            reducerName(state, action);
            return{
                ...state,
                showPrinting: true,
                shown: true,
                launched: true,
                jobs: action.payload.response.jobs
            };

        case eventConstants.POPUP_OPEN_PRINT_TICKET:
            reducerName(state, action);

            return{
                ...state,
                shown: true,
                launched: true,
                inputResult: "",
                tickets: action.payload.tickets
            };

        case eventConstants.API_LIGHT_FAIL:
            reducerName(state, action);

            return {
                ...state,
                permitLogoutCall: false,
            };

        case eventConstants.POPUP_OPEN_PRINT_TICKET_ENTRY_TEXT:
            reducerName(state, action);


            return {
                ...state,
               inputResult: action.payload.inputResult,
                enableButton: action.payload.inputResult.length !== 0
            };

        case eventConstants.POPUP_PRINT_TICKET_DONE:
        case eventConstants.POPUP_OPEN_PRINT_TICKET_BACK:
            reducerName(state, action);

            return {
                ...state,
                shown: false
            };


        default:
            return state;
    }
};

const reducerName = (state, action) => {
    if (globalConstants.DEV_ENABLE_LOG === true) {
        console.log("--- REDUCER: popupNameEntryState     ", action.type);
        console.log("state:  ", state);
        console.log("action:  ", action);
    }
};

export default popupNameEntryState;
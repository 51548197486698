import React, {Component} from 'react';
import {connect} from "react-redux";
import {sendLoginForm, onChangeLoginForm} from "../../../actions";
import {eventConstants, getText, globalConstants, globalData} from "../../../constants";
import loginAction from "../../../actions/login.action"
import {buildUrlFormat, translateTexts} from "../../../constants/utils";

class LoginContent extends Component {

    constructor(props) {
        super(props);
    }

    handleSubmit(e) {
        if (e) e.preventDefault();
        let {loginState} = this.props;
        let {loginAction} = this.props;

        loginAction({
            type: globalConstants.API,
            success:eventConstants.SUCCESS_DETECT,
            fail: eventConstants.FAIL_DETECT,
            url: loginState.API_BASE + globalConstants.API_DETECT + "?user=" + loginState.user + "&pass=" + loginState.pass
        });
    }

    setValue(field, event) {
        let {loginAction} = this.props;
        loginAction({
            type: eventConstants.FORM_CHANGE,
            field,
            val: event.target.value
        });
    }

    translateText(pText) {
        let {loginState} = this.props;
        return loginState.language[loginState.currentLang].texts[pText];
    }

    componentDidMount() {
        this.autoLogin();
    }

    autoLogin() {
        let {loginState} = this.props;
        let lastLoginSuccess = localStorage.getItem(globalConstants.LAST_LOGIN_SUCCESS);
        lastLoginSuccess = lastLoginSuccess ? !!lastLoginSuccess : false;
        if (globalConstants.INSTANT_LOGIN === true && lastLoginSuccess) {
            if(!window.cordova){
                if (loginState.user && loginState.pass) {
                    this.handleSubmit();
                }
            }
        }
    }

    render() {
        let {loginState} = this.props;

        let texts = loginState.language[loginState.currentLang].login["texts"];
        let buttons = loginState.language[loginState.currentLang].login["buttons"];

        return (
            <div className="smartq-app-login">
                <div className="smartq-app-login-content">
                    <div className="form">
                        <form onSubmit={this.handleSubmit.bind(this)}>
                            <h1 className="title">{translateTexts(texts, "text")}</h1>
							<div className="form-block-inputs">
								<div className="form-block">
									<input ref="user" className="form-input" type="text"
										   placeholder={translateTexts(texts, "username")}
										   defaultValue={loginState.user} name="name"
										   onChange={this.setValue.bind(this, 'user')}/>
								</div>
								<div className="form-block">
									<input ref="pass" className="form-input" type="password"
										   placeholder={translateTexts(texts, "password")}
										   defaultValue={loginState.pass} name="password"
										   onChange={this.setValue.bind(this, 'pass')}
									/>
								</div>
							</div>
                            <div className="form-block form-block-buttons">
                                <button type="submit" className="login">
                                    <span className="texts">{translateTexts(buttons, "login")}</span>
                                </button>
                                <button className="exit" onClick={(e) => this.exitNow(e)}>
                                    <span className="texts">{translateTexts(buttons, "exit")}</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    exitNow(e){
        if(e) e.preventDefault();

        let {loginAction} = this.props;
        loginAction({
            type: eventConstants.SHOW_EXIT_CONFIRMATION
        });
    }
}

const mapStateToProps = state => ({
    loginState: state.loginState
});

const mapDispatchToProps = {
    loginAction,
    onChangeLoginForm
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContent);
import React, { Component } from 'react';
import { ReactComponent as LoginLogo} from '../../../assets/img/logo.svg';

class FormHeading extends Component {
    render() {
         return (
            <div className="logo-holder">
                <div className="form-heading">
                    <div className="logo">
                        <LoginLogo className="smartq-logo" id="smartq-logo"/>
                    </div>
                </div>
            </div>

        );
    }
}

export default FormHeading;


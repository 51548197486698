import {eventConstants, globalConstants} from "../constants";

const adAction = (payload) => {
    switch (payload.type) {

        case eventConstants.TIMER_START:
        case eventConstants.TIMER_STOP:
        case eventConstants.CALL_RECALL_EXECUTE:
        case eventConstants.SHOW_RATING_SCREEN:
        case eventConstants.RATE_NOW:
        case eventConstants.AD_RATING_TICKET_INFO_CALL:
        case eventConstants.AD_RATING_DATA_CALL:
        case eventConstants.AD_SERVER_CALL:
        case eventConstants.SHOW_NEXT_MEDIA_ITEM:
        case eventConstants.OPERATOR_SERVER_CALL:
        case globalConstants.API:
        //    componentActionName(payload);
            return {
                type: payload.type,
                payload
            };

        default:
            return {
                type: payload.type,
                payload
            };
    }
};

const componentActionName = (payload) => {
    if (globalConstants.DEV_ENABLE_LOG === true) {
        console.log("--- ACTION: adAction     ", payload.type);
        console.log("payload:  ", payload);
    }
};

export default adAction;
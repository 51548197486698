import React, { Component } from 'react';
import { connect } from "react-redux";
import {eventConstants, globalConstants, operatorPopupConstants} from "../../constants";
import {ReactComponent as LogoutIcon} from "../../assets/img/logout.svg";
import popupLogoutAction from "../../actions/popupLogout.action";
import PinInputItem from "./PinInputItem";
import {Linear, TweenMax} from "gsap";
import {isEmpty, translateText, translateTextAlternate, translateTextPopup} from "../../constants/utils";

class PopupLogout extends Component {

    constructor(props){
        super(props);

        this.myTween = null;
        this.theComponent = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.theComponent.current){
            let {popupLogoutState, popupLogoutAction, loginState } = this.props;
            if(popupLogoutState.shown === true){
                this.myTween = TweenMax.to(this.theComponent.current, 0.2,
                    {autoAlpha:1, ease: Linear.easeNone});
            } else {
                this.myTween = TweenMax.to(this.theComponent.current, 0.2,
                    {autoAlpha:0, ease: Linear.easeNone});
            }

            if(popupLogoutState.permitLogoutCall === true){
                popupLogoutAction({
                    type: globalConstants.API,
                    success: eventConstants.PIN_SUCCESS,
                    fail: eventConstants.API_LIGHT_FAIL,
                    url: loginState.config.pin + loginState.loginAuth  + "&pin=" + popupLogoutState.logoutInputResult
                });
            }
        }
    }

    backButtonPressed(e){
        if(e) e.preventDefault();
        let {popupLogoutAction} = this.props;
        popupLogoutAction({
            type: eventConstants.POPUP_OPEN_SELECT_TICKET_BACK
        });
    }

    buttonPressed(e){
        if(e) e.preventDefault();

        popupLogoutAction({
            type: eventConstants.POPUP_OPEN_SELECT_TICKET_ITEM,
            itemKey: this.props.itemKey
        });
    }


    buttonPressLogout(e) {
        if (e) e.preventDefault();
        let {popupLogoutState, popupLogoutAction, loginState } = this.props;


        popupLogoutAction({
            type: eventConstants.OPERATOR_SERVER_CALL,
            success: eventConstants.LOGOUT_SUCCESS,
            fail: null,
            butType: "",
            popup:  operatorPopupConstants.POPUP_TYPE_CONFIRMATION,
            popupSubType: operatorPopupConstants.POPUP_LOGOUT,
            popupConfirmed: 0,
            popupRequired: 1,
            url: loginState.config.logout + loginState.loginAuth  + "&pin=" + popupLogoutState.logoutInputResult
        });

    }

    buttonPressExit(e) {
        if (e) e.preventDefault();
        let {popupLogoutState, popupLogoutAction, loginState } = this.props;


        popupLogoutAction({
            type: eventConstants.OPERATOR_ACTION,
            subType: eventConstants.TRANSFER_BUTTON_ACTION_OPERATOR,
            success: eventConstants.POPUP_ACTION_EXIT_SUCCESS,
            fail: null,
            butType: "",
            popup:  operatorPopupConstants.POPUP_TYPE_CONFIRMATION,
            popupSubType: operatorPopupConstants.POPUP_ACTION_EXIT,
            popupConfirmed: 0,
            popupRequired: 1
        });
    }

    renderPinSettingsPopup(){
        let {popupLogoutState, loginState} = this.props;

        if(popupLogoutState.launched === false){
            return null;
        }

        if(popupLogoutState.confirmPopup === true){
            return null;
        }

        let hidePinPadClass = popupLogoutState.enableLogoutButtons ? "numpad_holder disabled" : "numpad_holder";
        let pinMode = !popupLogoutState.enableLogoutButtons;
        let parentTranslateObj = loginState.language[loginState.currentLang][loginState.config.type];
        if(!isEmpty(loginState.config.subtype)){
            parentTranslateObj = loginState.language[loginState.currentLang][loginState.config.type][loginState.config.subtype];
        }
        return(
            <div className="inside-content">
                <div className="popup-operator-content">

                    <div className="popup-title">
                        <div className="title">{pinMode===true?translateTextAlternate(parentTranslateObj, "pin", "title" ):translateTextAlternate(parentTranslateObj, "options", "title" )}</div>
                    </div>

                    <div className="buttons-section">
                        <div className={hidePinPadClass}>
                            <div className="numpad_result_parent">
                                <span className="numpad_result">{popupLogoutState.logoutInputResult}</span>
                            </div>
                            <div className="numpad">
                                <div className="numpad_buttons">
                                    <PinInputItem value="0"/>
                                    <PinInputItem value="1"/>
                                    <PinInputItem value="2"/>
                                    <PinInputItem value="3"/>
                                    <PinInputItem value="4"/>
                                    <PinInputItem value="5"/>
                                    <PinInputItem value="6"/>
                                    <PinInputItem value="7"/>
                                    <PinInputItem value="8"/>
                                    <PinInputItem value="9"/>

                                    <PinInputItem value="10"/>
                                </div>
                            </div>
                        </div>


                        <button
                            onClick={(e) => this.buttonPressLogout(e)}
                            className={popupLogoutState.enableLogoutButtons ? "" : "disabled"}
                        >
                            <div className="icon">
                                <LogoutIcon/>
                            </div>
                            <span className="texts">{translateTextAlternate(parentTranslateObj, "options", "btn", "logout")}</span>
                        </button>
                        <button
                            onClick={(e) => this.buttonPressExit(e)}
                            className={popupLogoutState.enableLogoutButtons ? "" : "disabled"}
                        >
                            <div className="icon">
                                <LogoutIcon/>
                            </div>
                            <span className="texts">{translateTextAlternate(parentTranslateObj, "options", "btn", "exit")}</span>
                        </button>
                    </div>

                    <div className="action-buttons">
                        <button className="back-action" type="button"
                                onClick={(e) => this.backButtonPressed(e)}
                        >
                            <span className="texts">{translateTextAlternate(parentTranslateObj, "options", "btn", "cancel")}</span>
                        </button>
                    </div>
                </div>
            </div>
        )
    }


    renderConfirmationPopup(){

        let {popupLogoutState} = this.props;
        if(popupLogoutState.confirmPopup === false){
            return null;
        }

        let componentData = this.props.componentData;

            let {loginState, operatorState} = this.props;
            let title, body, btnOk, btnCancel;

            let confirmedMode = "";

            switch (popupLogoutState.confirmPopupSubType) {

                case operatorPopupConstants.POPUP_LOGOUT:
                    confirmedMode = "logout";
                    break;
                case operatorPopupConstants.POPUP_ACTION_EXIT:
                    confirmedMode = "exit";
                    break;

                default:
                    console.log(" no popup type provided ! ");
            }



            let parentTranslateObj = loginState.language[loginState.currentLang][loginState.config.type];
            if(!isEmpty(loginState.config.subtype)){
                parentTranslateObj = loginState.language[loginState.currentLang][loginState.config.type][loginState.config.subtype];
            }

            title = translateTextAlternate(parentTranslateObj, confirmedMode, "title");
            body = translateTextAlternate(parentTranslateObj, confirmedMode, "body");

            btnOk = translateTextAlternate(parentTranslateObj, confirmedMode, "btn", "ok");
            btnCancel = translateTextAlternate(parentTranslateObj, confirmedMode, "btn", "cancel");

            if(isEmpty(title)){
                title = "";
            }

            if(isEmpty(body)){
                body = "";
            }

            if(isEmpty(btnOk)){
                btnOk = "";
            }

            if(isEmpty(btnCancel)){
                btnCancel = "";
            }

            return (
                <div className="inside-content">
                    <div className="popup-confirm-cancel">
                        <div className="title">{title}</div>
                        <div className="guidance">{body}</div>
                        <div className="action-buttons">
                            {this.renderOkButton(btnOk)}
                            {this.renderCancelButton(btnCancel)}
                        </div>
                    </div>
                </div>
            )

    }

    renderOkButton(pValue) {
       let{popupLogoutState} = this.props;

        if (!isEmpty(pValue)) {
            return (
                <button className="confirm-action" type="button" ref={this.buttonConfirm}
                        disabled={!popupLogoutState.shown}
                        onClick={(e) => this.buttonPressConfirm(e)}>
                    <span className="texts">{pValue}</span>
                </button>
            )
        } else {
            return null;
        }
    }

    buttonPressConfirm(e) {
        if (e) e.preventDefault();

        let {popupLogoutState, popupLogoutAction} = this.props;
        let popupPayload = popupLogoutState.popupPayload;

        popupLogoutAction({
            type: popupPayload.type,
            ...popupPayload,
            popupConfirmed: 1
        });
    }

    buttonPressCancel(e) {
        if (e) e.preventDefault();
        let {popupLogoutState, popupLogoutAction} = this.props;

        popupLogoutAction({
            type: eventConstants.PIN_SUCCESS
        })
    }

    renderCancelButton(pValue) {
        let{popupLogoutState} = this.props;

        if (!isEmpty(pValue)) {
            return (
                <button className="cancel-action" type="button" ref={this.buttonCancel}
                        disabled={!popupLogoutState.shown}
                        onClick={(e) => this.buttonPressCancel(e)}>
                    <span className="texts">{pValue}</span>
                </button>
            )
        } else {
            return null;
        }
    }

    render() {
        let {popupLogoutState, loginState} = this.props;

        if(popupLogoutState.launched === false){
            return null;
        }

        return (
            <div className="smartq-popup" ref={this.theComponent}>
                <div className="popup-overlay"></div>
                <div className="popup-data">
                    <div className="popup-container">
                        <div className="popup-content">
                            <div className="container">
                                {this.renderPinSettingsPopup()}
                                {this.renderConfirmationPopup()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    popupLogoutState: state.popupLogoutState,
    loginState: state.loginState
});

const mapDispatchToProps =  {
    popupLogoutAction
};

export default connect(mapStateToProps, mapDispatchToProps)(PopupLogout);




/*  popupLogoutAction({
      type: globalConstants.API,
      success: eventConstants.LOGOUT_SUCCESS,
      fail: eventConstants.API_LIGHT_FAIL,
      url: loginState.config.logout + loginState.loginAuth  + "&pin=" + popupLogoutState.logoutInputResult
  });*/
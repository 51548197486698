import '../../assets/css/dispenser/dispenser.css';
import '../../assets/css/dispenser-single/dispenser-single.css';
import '../../assets/css/dispenser/dispenser.css';
import React, { Component } from 'react';
import { connect } from "react-redux";
import {operatorPopupCall, ticketPrintCall} from "../../actions";
import {eventConstants, globalData, operatorPopupConstants, serverFields} from "../../constants";
import { ReactComponent as CheckMark} from '../../assets/img/check.svg';

class PopupOperatorTransferItem extends Component {

    constructor(props){
        super(props);

        this.myButton = React.createRef();
    }

    buttonPressed(e){
       if(e) e.preventDefault();

        this.props.operatorPopupCall({
            type: operatorPopupConstants.TRANSFER_ITEM_SELECTED,
            transferSelected: this.getItemId(),
            transferSelectedType: this.getIdType()
        });
    }

    getItemId(){
        if(this.props.item.oid){
            return this.props.item.oid;
        } else if(this.props.item.jid){
            return this.props.item.jid;
        }
    }

    getIdType(){
        if(this.props.item.oid){
            return "oid";
        } else if(this.props.item.jid){
            return "jid";
        }
    }
    render() {
        let buttonClass = this.props.transferSelected === this.getItemId() ? "active" : "";
        return (
            <button type="button" className={buttonClass} ref={this.myButton}
                    onClick={(e) => this.buttonPressed(e)}
            >
                <span className="ticket-name">{this.props.item.name}</span>
                <span className="methode"></span>
            </button>
        );
    }
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps =  {
    operatorPopupCall
};


export default connect(mapStateToProps, mapDispatchToProps)(PopupOperatorTransferItem);

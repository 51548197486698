import '../../assets/css/operator/operator.css';
import React, { Component } from 'react';
import { connect} from "react-redux";
import { operatorCall } from "../../actions";
import {buttonsConstants, eventConstants, operatorPopupConstants} from "../../constants";

class ChooseTicketItem extends Component {

    constructor(props){
        super(props);

        this.componentButton = React.createRef();
    }

    buttonPress(e) {
        let componentData = this.props;

        if (e) e.preventDefault();

        let {loginState} = this.props;

        this.props.operatorCall({
            type: eventConstants.OPERATOR_SERVER_CALL,
            subType: eventConstants.NEXT_ACTION_OPERATOR,
            success: eventConstants.NEXT_ACTION_SUCCESS,
            fail: null,
            butType: buttonsConstants.NEXT_BUTTON,
            popup: operatorPopupConstants.POPUP_TYPE_CONFIRMATION,
            popupSubType: this.getPopupType(),
            popupRequired: this.getPopupRequired(),
            popupConfirmed: 0,
            ticketNoRequested: componentData.item.no,
            url: loginState.config.actions.ticket_call +
                loginState.loginAuth + "&ticket=" +
                componentData.item.no
        });
    }

    getPopupType(){
        switch (this.props.buttonType) {
           case buttonsConstants.DROPPED_BUTTON:
                return operatorPopupConstants.POPUP_POSTPONED_BUTTON;

            case buttonsConstants.MANUAL_BUTTON:
                return operatorPopupConstants.POPUP_POSTPONED_BUTTON;

            case buttonsConstants.POSTPONED_BUTTON:
                return operatorPopupConstants.POPUP_POSTPONED_BUTTON;
        }
    }

    getPopupRequired(){
        let {loginState} = this.props;

        switch (this.props.buttonType) {
            case buttonsConstants.DROPPED_BUTTON:
                return loginState.config.popups.dropped;

            case buttonsConstants.MANUAL_BUTTON:
                return loginState.config.popups.manual;

            case buttonsConstants.POSTPONED_BUTTON:
                return loginState.config.popups.postponed;
        }
    }
    render() {
        let componentData = this.props;
        return (
            <button type="button" ref={this.componentButton}
                    onClick={(e) => this.buttonPress(e)}
            >
                <span className="texts">{componentData.item.no}</span>
            </button>
        );
    }
}

const mapStateToProps = (state) => ({
    loginState: state.loginState
});

const mapDispatchToProps =  {
    operatorCall
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseTicketItem);
import {eventConstants, globalConstants, operatorPopupConstants} from "../constants";

const initialState = {
    shown: false,
    launched: false,
    logoutInputResult: "",
    enableLogoutButtons: false,
    confirmPopup: false,
    confirmPopupSubType: "",
    popupPayload: {},
};

const popupLogoutState = (state = initialState, action) => {
    switch (action.type) {



        case operatorPopupConstants.POPUP_TYPE_CONFIRMATION:
            reducerName(state, action);

            return{
                ...state,
                confirmPopup: true,
                confirmPopupSubType: action.payload.popupSubType,
                popupPayload: action.payload
            };

        case eventConstants.LOGOUT_SUCCESS:
            reducerName(state, action);

            return{
                ...state,
                ...initialState
            };

        case eventConstants.PIN_SUCCESS:
            reducerName(state, action);

            return {
                ...state,
                enableLogoutButtons: true,
                permitLogoutCall: false,
                confirmPopup: false
            };

        case eventConstants.API_LIGHT_FAIL:
            reducerName(state, action);

            return {
                ...state,
                permitLogoutCall: false,
            };

        case eventConstants.POPUP_OPEN_SELECT_TICKET_BACK:
            reducerName(state, action);

            return {
                ...state,
                shown: false,
                logoutInputResult: ""
            };


        case eventConstants.PIN_NEW_CHAR_INPUT:
            reducerName(state, action);

            let newResult = state.logoutInputResult;

            if (action.payload.value !== "DEL") {
                if (newResult.length < 6) {
                    newResult += action.payload.value;
                }

            } else {
                if (newResult.length > 0) {
                    newResult = newResult.slice(0, -1);
                }
            }

            return {
                ...state,
                permitLogoutCall: true,
                logoutInputResult: newResult
            };

        case eventConstants.SHOW_POPUP_LOGOUT:
            reducerName(state, action);

            return {
                ...state,
                shown: true,
                launched: true,
                enableLogoutButtons: false,
                confirmPopup: false
            };


        default:
            return state;
    }
};

const reducerName = (state, action) => {
    if (globalConstants.DEV_ENABLE_LOG === true) {
        console.log("--- REDUCER: popupLogoutState     ", action.type);
        console.log("state:  ", state);
        console.log("action:  ", action);
    }
};

export default popupLogoutState;
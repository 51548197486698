import {eventConstants, globalConstants} from "../constants";

const initialState = {
    time: 0,
    hours: 0,
    minutes: 0
};

const clockState = (state = initialState, action) => {

    switch (action.type) {
        case eventConstants.UPDATE_NEWS_FEED_CLOCK:
            return {
                ...state,
                ...getTime()
            };

        default:
            return state;
    }
};

const reducerName = (state, action) => {
    if (globalConstants.DEV_ENABLE_LOG === true) {
        console.log("--- REDUCER: clockState     ", action.type);
        console.log("state:  ", state);
        console.log("action:  ", action);
    }
};


const getTime = () => {
    let myDate = new Date();
    let hours = myDate.getHours();
    let minutes = myDate.getMinutes();
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return {
        time: hours + ":" + minutes,
        hours,
        minutes
    }
};

export default clockState;
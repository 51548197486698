import '../../assets/css/operator/operator.css';
import React, { Component } from 'react';
import { connect} from "react-redux";
import { operatorCall } from "../../actions";
import {buttonsConstants, eventConstants, operatorPopupConstants} from "../../constants";
import { ReactComponent as TakeIcon} from '../../assets/img/take.svg';
import { ReactComponent as TransferIcon} from '../../assets/img/transfer.svg';
import { ReactComponent as DropIcon} from '../../assets/img/drop.svg';
import { ReactComponent as RecallIcon} from '../../assets/img/recall.svg';
import { ReactComponent as PostponeIcon} from '../../assets/img/postpone.svg';
import { ReactComponent as FinishIcon} from '../../assets/img/check.svg';
import operatorAction from "../../actions/operator.action";
import {translateText, translateTexts} from "../../constants/utils";

class ChooseListItem extends Component {

    constructor(props){
        super(props);
        this.killTimeout = null

        this.componentButton = React.createRef();
    }

    buttonPress(e){
        if(e) e.preventDefault();
        let buttonType = this.props.buttonType;
        let {loginState, operatorState, operatorAction } = this.props;

        switch (buttonType) {
            case buttonsConstants.TAKE_BUTTON:
                operatorAction({
                    type: eventConstants.OPERATOR_SERVER_CALL,
                    success: eventConstants.TAKE_BUTTON_ACTION_SUCCESS,
                    fail: null,
                    popup: operatorPopupConstants.POPUP_TYPE_CONFIRMATION,
                    popupSubType: operatorPopupConstants.POPUP_TAKE,
                    popupRequired: loginState.config.popups.take,
                    popupConfirmed: 0,
                    url: loginState.config.actions.ticket_take + loginState.loginAuth + "&ticket=" +
                        operatorState.ticketNumber
                });
                break;

            case buttonsConstants.TRANSFER_BUTTON:
                operatorAction({
                    type: eventConstants.OPERATOR_SERVER_CALL,
                    success: eventConstants.TRANSFER_BUTTON_ACTION_SUCCESS_POPUP,
                    fail: null,
                    popup:  operatorPopupConstants.POPUP_TYPE_TRANSFER,
                    ticketNumber: operatorState.ticketNumber,
                    url: loginState.config.actions.operators_list +
                        loginState.loginAuth
                });
                break;

            case buttonsConstants.DROP_BUTTON:
                operatorAction({
                    type: eventConstants.OPERATOR_SERVER_CALL,
                    success: eventConstants.DROP_BUTTON_ACTION_SUCCESS,
                    fail: null,
                    popup:  operatorPopupConstants.POPUP_TYPE_CONFIRMATION,
                    popupSubType: operatorPopupConstants.POPUP_DROP,
                    popupConfirmed: 0,
                    popupRequired: loginState.config.popups.drop,
                    url: loginState.config.actions.ticket_drop +
                        loginState.loginAuth + "&ticket=" +
                        operatorState.ticketNumber
                });
                break;

            case buttonsConstants.RECALL_BUTTON:
                if (loginState.config.popups.recall !== 1) {

                    if (this.recallTimeout === true) {
                        clearTimeout(this.recallTimeout);
                    } else {
                        this.recallTimeout = setTimeout(this.enableRecall.bind(this),
                            loginState.config.recall.disable_after_call * 1000);
                    }

                    operatorAction({
                        type: eventConstants.OPERATOR_SERVER_CALL,
                        success: eventConstants.RECALL_BUTTON_ACTION_SUCCESS,
                        fail: null,
                        popup: null,
                        url: loginState.config.actions.ticket_recall +
                            loginState.loginAuth + "&ticket=" +
                            operatorState.ticketNumber
                    });
                } else {
                    operatorAction({
                        type: eventConstants.OPERATOR_SERVER_CALL,
                        success: eventConstants.RECALL_BUTTON_ACTION_SUCCESS,
                        fail: null,
                        popup: operatorPopupConstants.POPUP_TYPE_CONFIRMATION,
                        popupSubType: operatorPopupConstants.POPUP_RECALL,
                        popupRequired: loginState.config.popups.recall,
                        popupConfirmed: 0,
                        timeoutInterval: loginState.config.recall.disable_after_call * 1000,
                        recallLimit: loginState.config.recall.disable_after_recall,
                        url: loginState.config.actions.ticket_recall +
                            loginState.loginAuth + "&ticket=" +
                            operatorState.ticketNumber
                    });
                }

                break;

            case buttonsConstants.POSTPONE_BUTTON:
                operatorAction({
                    type: eventConstants.OPERATOR_SERVER_CALL,
                    success: eventConstants.POSTPONE_BUTTON_ACTION_SUCCESS,
                    fail: null,
                    popup: operatorPopupConstants.POPUP_TYPE_CONFIRMATION,
                    popupSubType: operatorPopupConstants.POPUP_POSTPONE,
                    popupRequired: loginState.config.popups.postpone,
                    popupConfirmed: 0,
                    url: loginState.config.actions.ticket_postpone +
                        loginState.loginAuth + "&ticket=" +
                        operatorState.ticketNumber
                });
                break;

            case buttonsConstants.FINISH_BUTTON:
                operatorAction({
                    type: eventConstants.OPERATOR_SERVER_CALL,
                    success: eventConstants.FINISH_BUTTON_ACTION_SUCCESS,
                    fail: null,
                    popup: operatorPopupConstants.POPUP_TYPE_CONFIRMATION,
                    popupSubType: operatorPopupConstants.POPUP_FINISH,
                    popupRequired: loginState.config.popups.finish,
                    popupConfirmed: 0,
                    url: loginState.config.actions.ticket_finish +
                        loginState.loginAuth + "&ticket=" +
                        operatorState.ticketNumber
                });
                break;

            default:
                return null;
        }
    }

    getDisabledButtonStatus() {
        let componentData = this.props;
        let ticketStatus = componentData.ticketStatus;
        let buttonType = componentData.buttonType;
        let {operatorState} = this.props;
        switch (ticketStatus) {
            case 2: // CALLING
            case 3: // RECALL
                switch (buttonType) {
                    case buttonsConstants.RECALL_BUTTON:
                        return operatorState.recallEnabled === false ? "disabled" : "";
                    case buttonsConstants.FINISH_BUTTON:
                    case buttonsConstants.POSTPONE_BUTTON:
                    case buttonsConstants.TRANSFER_BUTTON:
                        return "disabled";
                    default:
                        return "";
                }
            case 4: // IN WORK
                switch (buttonType) {
                    case buttonsConstants.TAKE_BUTTON:
                    case buttonsConstants.DROP_BUTTON:
                    case buttonsConstants.RECALL_BUTTON:
                        return "disabled";
                    default: return "";
                }
            default: return "";
        }
    }

    renderText(){
        let componentProps = this.props;
        let {loginState, operatorState} = this.props;

        let texts = loginState.language[loginState.currentLang][loginState.config.type]["texts"];
        let buttons = loginState.language[loginState.currentLang][loginState.config.type]["buttons"];


        let buttonType = componentProps.buttonType;
        switch (buttonType) {
            case buttonsConstants.TAKE_BUTTON:
                return translateTexts(buttons, "take");
            case buttonsConstants.TRANSFER_BUTTON:
                return translateTexts(buttons, "transfer");
            case buttonsConstants.DROP_BUTTON:
                return translateTexts(buttons, "drop");
            case buttonsConstants.RECALL_BUTTON:
                if (operatorState.recallIteration !== 0) {
                    return translateTexts(buttons, "recall") +" (" + operatorState.recallIteration + ")";
                } else {
                    return translateTexts(buttons, "recall");
                }
            case buttonsConstants.POSTPONE_BUTTON:
                return translateTexts(buttons, "postpone");
            case buttonsConstants.FINISH_BUTTON:
                return translateTexts(buttons, "finish");
            default:
                return null;
        }
    }

    renderIcon() {
        let componentData = this.props;
        switch (componentData.buttonType) {
            case buttonsConstants.TAKE_BUTTON:
                return (
                    <TakeIcon/>
                );
            case buttonsConstants.TRANSFER_BUTTON:
                return (
                    <TransferIcon/>
                );
            case buttonsConstants.DROP_BUTTON:
                return (
                    <DropIcon/>
                );
            case buttonsConstants.RECALL_BUTTON:
                return (
                    <RecallIcon/>
                );
            case buttonsConstants.POSTPONE_BUTTON:
                return (
                    <PostponeIcon/>
                );
            case buttonsConstants.FINISH_BUTTON:
                return (
                    <FinishIcon/>
                );
            default:
                return null;
        }
    }

    render() {
        let disabledStatus = this.getDisabledButtonStatus();
        let {loginState, operatorState} = this.props;

        return (
            <button type="button" ref={this.componentButton}
                    onClick={(e) => this.buttonPress(e)}
                    className={disabledStatus}
                    disabled={disabledStatus !== ""}
            >
                <span className="icon">
                      {this.renderIcon()}
                 </span>
                <span className="texts">{this.renderText()}</span>
            </button>
        );
    }

    enableRecall(){
        let {loginState, operatorState} = this.props;

        if(operatorState.recallIteration < loginState.config.recall.disable_after_recall){
            this.props.operatorCall({
                type: eventConstants.ENABLE_RECALL
            });
        }
    }

}

const mapStateToProps = (state) => ({
    loginState: state.loginState,
    operatorState: state.operatorState
});

const mapDispatchToProps =  {
    operatorCall,
    operatorAction
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseListItem);


/* operatorAction({
                  type: eventConstants.OPERATOR_SERVER_CALL,
                  success: eventConstants.RECALL_BUTTON_ACTION_SUCCESS,
                  fail: null,
                  popup: null,
                  url: loginState.config.actions.ticket_recall +
                      loginState.loginAuth + "&ticket=" +
                      operatorState.ticketNumber
              });*/

/*
    enableRecall(){
        let {loginState, operatorState} = this.props;

        if(operatorState.recallIteration < loginState.config.recall.disable_after_recall){
            this.props.operatorCall({
                type: eventConstants.ENABLE_RECALL
            });
        }
    }*/


/*  this.componentButton.current.disabled = "disabled";
             this.componentButton.current.classList = "disabled";
             if(this.props.componentData.killTimeout === true){
                 clearTimeout(this.recallTimeout);
             } else{
                 this.recallTimeout = setTimeout(this.enableRecall.bind(this),
                     loginState.config.recall.disable_after_call * 1000);
             }*/
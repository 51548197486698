import '../../assets/css/login/login.css';
import React, { Component } from 'react';
import {TimelineMax, TweenMax, Linear, Power0, Elastic, Power2, Bounce} from 'gsap';

import { connect} from "react-redux";
import {animNewsCall, apiActionCall} from "../../actions";
import {eventConstants, globalConstants} from "../../constants";
import axios from "axios";
import newsfeedAction from "../../actions/newsfeed.action";



class NewsFeedItem extends Component {

    constructor(props){
        super(props);

        this.loading = false;
        this.element = React.createRef();
        this.elementSpan = React.createRef();

        this.myShowTween = null;
        this.myHideTween = null;
        this.stayTimeout = null;

        this.holderWidth = 0;
        this.contentWidth = 0;

        this.scrollTolerance = 50;

        this.yoyoMode = false;

        this.localConfig = {
            INACTIVE_CSS_CLASS: "inlineBlockDisplay",
            ACTIVE_CSS_CLASS: "activated"
        };

        this.hideStyle = {
            showInitialPosY: 0,
            centerPosY: 0,
            hideEndPosY: 0
        };

        this.blinkIncrement = 0;
        this.blinkTimer = null;
        this.waitBlinkTimeout = null;

        this.componentData = null;
        this.config = null;
    }

    mapToDefaults(config){
        let newConfig = {...config};
        newConfig.directionStart === ""  && (newConfig.directionStart = "LEFT");
        newConfig.directionEnd === ""  && (newConfig.directionEnd = "RIGHT");
        newConfig.stayTime === 0  && (newConfig.stayTime = 2);
        newConfig.showSpeed === 0  && (newConfig.showSpeed = 2);
        newConfig.scrollSpeed === 0  && (newConfig.scrollSpeed = 10);
        newConfig.scrollYoyo === 4  && (newConfig.scrollYoyo = 4);
        newConfig.scrollEasing === ""  && (newConfig.scrollEasing = "easeOut");
        return newConfig;
    }

    componentWillUnmount() {
        this.killAllTimers();
        this.killAllTweens();
        if(this.config.blink === true){
            clearInterval(this.blinkTimer);
            clearTimeout(this.waitBlinkTimeout);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        clearTimeout(this.stayTimeout);
        this.killAllTweens();
        let { newsFeedState } = this.props;

        this.holderWidth = newsFeedState.wrapperWidth;
        this.contentWidth = this.elementSpan.current.offsetWidth;
        this.animateTicker();
    }

    killAllTimers() {
        clearTimeout(this.stayTimeout);
        if(this.blinkTimer){
            clearInterval(this.blinkTimer);
        }
        if(this.waitBlinkTimeout){
            clearTimeout(this.waitBlinkTimeout);
        }
    }

    killAllTweens() {
        if (this.myShowTween) {
            if (TweenMax.isTweening(this.myShowTween)) {
                this.myShowTween.kill();
            }
        }
        if (this.myHideTween) {
            if (TweenMax.isTweening(this.myHideTween)) {
                this.myHideTween.kill();
            }
        }
    }

    animateTicker() {
        let { newsFeedState, order} = this.props;

        this.detectPositions();

        if (newsFeedState.currentTextIdx === order) {
            if(this.yoyoMode === true){
                this.myShowTween = TweenMax.fromTo(this.element.current, this.config.showSpeed,
                    {   x: this.hideStyle.showInitialPosX,
                        y: this.hideStyle.showInitialPosY
                    },
                    {
                        x: this.scrollTolerance,
                        y: this.hideStyle.centerPosY,
                        onComplete: this.onCompleteShowAnimYOYO.bind(this),
                        ease: this.config.scrollEasing
                    }).play();
            } else {
                this.myShowTween = TweenMax.fromTo(this.element.current, this.config.showSpeed,
                    {   x: this.hideStyle.showInitialPosX,
                        y: this.hideStyle.showInitialPosY
                    },
                    {
                        x: this.hideStyle.centerPosX,
                        y: this.hideStyle.centerPosY,
                        onComplete: this.onCompleteShowAnim.bind(this),
                        ease: this.config.showEasing
                    }).play();
            }
        }
    }

    onCompleteShowAnimYOYO() {
        this.myShowTween = TweenMax.fromTo(this.element.current, this.config.scrollSpeed,
            {   x: this.scrollTolerance,
                y: 0
            },
            {
                x: Math.round(this.holderWidth - this.contentWidth - this.scrollTolerance),
                y: 0,
                repeat: this.config.scrollYoyo,
                yoyo: true,
                onComplete: this.onCompleteShowAnim.bind(this),
                ease: this.config.scrollEasing,
                yoyoEase: this.config.scrollEasing
            }).play();

        this.startBlinking();
    }

    onCompleteShowAnim() {
        if(this.blinkTimer){
            clearInterval(this.blinkTimer);
        }
        if(this.waitBlinkTimeout){
            clearTimeout(this.waitBlinkTimeout);
        }

        this.stayTimeout = setTimeout(() => {
            this.myHideTween = TweenMax.fromTo(this.element.current, this.config.hideSpeed,
                {   x: this.hideStyle.centerPosX,
                    y: this.hideStyle.centerPosY
                },
                {
                    x: this.hideStyle.hideEndPosX,
                    y: this.hideStyle.hideEndPosY,
                    onComplete: this.onCompleteEndAnim.bind(this),
                    ease: this.config.showEasing
                }).play();
        }, this.config.stayTime * 1000);


        this.startBlinking();
    }

    startBlinking(){
        if(this.config.blink === true){
            this.blinkIncrement = 0;
            this.blinkTimer = setInterval(()=>{
                this.blinkNow();
            }, this.config.blinkSpeed);
        }
    }


    blinkNow() {
        this.blinkIncrement++;

        if (this.blinkIncrement % 2 === 0) {
            this.elementSpan.current.style.color = this.config.textBlinkColor;
            this.props.heading.current.style.backgroundColor = this.config.bgBlinkColor;
        } else {
            this.elementSpan.current.style.color = this.config.textNormalColor;
            this.props.heading.current.style.backgroundColor = this.config.bgNormalColor;
        }

        if (this.blinkIncrement === this.config.blinkCycles) {
            clearInterval(this.blinkTimer);
            this.elementSpan.current.classList = "";
            this.waitBlinkTimeout = setTimeout(() =>{
                this.blinkIncrement = 0;
                this.startBlinking();
            }, this.config.blinkPause * 1000)
        }
    }


    onCompleteEndAnim() {
        let {newsfeedAction} = this.props;

        if (this.config.blink === true) {
            clearInterval(this.blinkTimer);
            clearTimeout(this.waitBlinkTimeout);
            this.elementSpan.current.style.color = this.config.textNormalColor;
            this.props.heading.current.style.backgroundColor = this.config.bgNormalColor;
        }

        if(this.element.current){
            this.element.current.style.display = "hidden";
        }


        newsfeedAction({
            type: eventConstants.NEWS_TICKER_ANIM_COMPLETE,
        });
    }

    getActiveCssClass(){
        let {newsFeedState} = this.props;

        if(newsFeedState.currentTextIdx === this.props.order){
            return this.localConfig.ACTIVE_CSS_CLASS;
        } else {
            return this.localConfig.INACTIVE_CSS_CLASS;
        }
    }

    detectPositions(){
        let { newsFeedState} = this.props;

        switch (this.config.directionStart) {
            case "RIGHT":
                this.hideStyle = {
                    ...this.hideStyle,
                    showInitialPosX: newsFeedState.wrapperWidth + newsFeedState.wrapperWidth/2
                };
                break;
            case "LEFT":
                this.hideStyle = {
                    ...this.hideStyle,
                    showInitialPosX: -Math.round(2 * newsFeedState.wrapperWidth - newsFeedState.wrapperWidth / 2)
                };
                break;
            case "TOP":
                this.hideStyle = {
                    ...this.hideStyle,
                    showInitialPosX: Math.round(newsFeedState.wrapperWidth / 2 - this.elementSpan.current.offsetWidth / 2),
                    showInitialPosY: -100
                };
                break;
            case "BOTTOM":
                this.hideStyle = {
                    ...this.hideStyle,
                    showInitialPosX: Math.round(newsFeedState.wrapperWidth / 2 - this.elementSpan.current.offsetWidth / 2),
                    showInitialPosY: 100
                };
                break;
            default:
        }

        switch (this.config.directionEnd) {
            case "LEFT":
                this.hideStyle = {
                    ...this.hideStyle,
                    hideEndPosX: Math.round(-newsFeedState.wrapperWidth - newsFeedState.wrapperWidth / 2)
                };
                break;
            case "RIGHT":
                this.hideStyle = {
                    ...this.hideStyle,
                    hideEndPosX: newsFeedState.wrapperWidth + newsFeedState.wrapperWidth/2
                };
                break;
            case "TOP":
                this.hideStyle = {
                    ...this.hideStyle,
                    hideInitialPosX: Math.round(newsFeedState.wrapperWidth / 2 - this.elementSpan.current.offsetWidth / 2),
                    hideEndPosY: -100
                };
                break;
            case "BOTTOM":
                this.hideStyle = {
                    ...this.hideStyle,
                    hideInitialPosX: Math.round(newsFeedState.wrapperWidth / 2 - this.elementSpan.current.offsetWidth / 2),
                    hideEndPosY: 100
                };
                break;
            default:
        }


        let centerPosX = 0;
        this.yoyoMode = false;
        if (this.holderWidth > this.contentWidth) {
            centerPosX = Math.round(this.holderWidth / 2 - this.contentWidth / 2);
        } else {
            centerPosX = this.scrollTolerance;
            this.yoyoMode = true;
        }

        this.hideStyle = {
            ...this.hideStyle,
            centerPosX: centerPosX
        };
    }

    render() {
        let { newsFeedState} = this.props;
console.log(" UPDATE news feed item ");
        this.config = this.mapToDefaults(newsFeedState.items[this.props.order].config);

        return (
            <div className={this.getActiveCssClass()}  ref={this.element}>
                <span ref={this.elementSpan}>{newsFeedState.items[this.props.order].content}</span>
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    newsFeedState: state.newsFeedState
});

const mapDispatchToProps =  {
    newsfeedAction
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsFeedItem);

import {buttonsConstants, eventConstants, globalConstants, operatorPopupConstants} from "../constants";


const operatorAction = (payload) => {
    switch (payload.type) {


        case eventConstants.UPDATE_OPERATOR_CONTENT:
            return {
                type: payload.type,
                payload
            };


        case eventConstants.POPUP_ACTION_EXIT:
            componentActionName(payload);
            return {
                type: payload.type,
                payload
            };

        case eventConstants.POPUP_OPEN_SELECT_TICKET_ITEM:
        case eventConstants.OPERATOR_ACTION:
        case eventConstants.OPERATOR_SERVER_CALL:
        case operatorPopupConstants.SHOW_SETTINGS_POPUP:
        case buttonsConstants.BUTTON_EVENT:
        case eventConstants.DROP_BUTTON_ACTION_OPERATOR:
        case eventConstants.NEXT_ACTION_OPERATOR:
        case eventConstants.ENABLE_RECALL:
        case eventConstants.POSTPONE_BUTTON_ACTION_OPERATOR:
        case eventConstants.TIMER_START:
            componentActionName(payload);
            return {
                type: payload.type,
                payload
            };

        default:
            return {
                type: payload.type,
                payload
            }
    }
};


const componentActionName = (payload) => {
    if (globalConstants.DEV_ENABLE_LOG === true) {
        console.log("--- ACTION: operatorAction     ", payload.type);
        console.log("payload:  ", payload);
    }
};


export default operatorAction;
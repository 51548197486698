import '../../assets/css/login/login.css';
import React, { Component } from 'react';
import { connect} from "react-redux";
import {ticketPrintCall} from "../../actions";
import {eventConstants, globalData} from "../../constants";
import {ReactComponent as CheckMark} from "../../assets/img/check.svg";
import ticketAction from "../../actions/ticket.action";
import EventBus from "../../constants/EventBus";

class TicketMainItem extends Component {

    constructor(props){
        super(props);

        this.single = true;
        this.myButton = React.createRef();
    }

    multiSubmitPress(e){
        if(e) e.preventDefault();

        let { ticketState, loginState, ticketAction } = this.props;


        if(this.single){
            if(loginState.config.print_button === "instant"){
                EventBus.getInstance().fireEvent("instantPrint", {
                    itemKey: this.props.itemKey
                })
            } else {
                ticketAction({
                    type: eventConstants.SELECT_TICKET_BUTTON,
                    itemKey: this.props.itemKey
                });
            }
        } else{
            let itemKey = this.props.itemKey;
            let componentItem = ticketState.items[itemKey];

            ticketAction({
                type: eventConstants.POPUP_OPEN_SELECT_TICKET,
                item: {...componentItem},
                popupId: componentItem.id
            });
        }
    }


    render() {
        let { ticketState, loginState } = this.props;

        let itemKey = this.props.itemKey;
        let componentItem = ticketState.items[itemKey];

        this.single = !componentItem.sub;

        let butWidth = componentItem.size === "half" ? "half-width" : "full-width";
        let disabledButton = componentItem.status === 0 ? "disabledButton" : "";
        let active = (componentItem.selected===true) ? "active" : "";

        let buttonClass = butWidth + " " + disabledButton + " " + active;

        return (
            <button
                ref={this.myButton}
                className={buttonClass}
                disabled={disabledButton}
                type="button" key={this.props.itemKey}
                onClick={(e) => this.multiSubmitPress(e)}>
                   <span className="icon">
                         <CheckMark className="developer-svg" id="developer-svg"/>
                   </span>
                <span className="texts">{componentItem.name[loginState.currentLang]}</span>
            </button>
        );
    }
}

const mapStateToProps = (state) => ({
    ticketState: state.ticketState,
    loginState: state.loginState
});

const mapDispatchToProps =  {
    ticketAction
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketMainItem);

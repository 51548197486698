import {eventConstants, globalConstants} from "../constants";
import {cloneDeep} from "lodash";

const initialState = {
    opened: false,
    popupType: "",
    popupId: -1,
    item: { }
};

const popupTicketState = (state = initialState, action) => {
    switch(action.type) {

        case eventConstants.POPUP_OPEN_SELECT_TICKET_ITEM:
            reducerName(state, action);

            let modifItem = cloneDeep(state.item);
            let newItem = modifItem.sub[action.payload.itemKey];
            console.log(modifItem);
            console.log(newItem);
            newItem.selected = newItem.selected !== true;

            return {
                ...state,
                item: {...modifItem}
            };

        case eventConstants.POPUP_OPEN_SELECT_TICKET_BACK:
            reducerName(state, action);

            return {
                ...state,
                opened: false
            };

        case eventConstants.POPUP_OPEN_SELECT_TICKET:
            reducerName(state, action);

            return {
                ...state,
                item: cloneDeep(action.payload.item),
                opened: true
            };

        default:
            return state;
    }
};

const reducerName = (state, action) => {
    if(globalConstants.DEV_ENABLE_LOG === true){
        console.log("--- REDUCER: popupTicketState     ", action.type);
        console.log("state:  ", state);
        console.log("action:  ", action);
    }
};

export default popupTicketState;
import '../../assets/css/dispenser/dispenser.css';
import '../../assets/css/dispenser-single/dispenser-single.css';
import '../../assets/css/dispenser/dispenser.css';
import React, {Component, Fragment} from 'react';
import { connect } from "react-redux";
import {eventConstants, globalConstants} from "../../constants";
import TicketMainItem from "./TicketMainItem";
import { ReactComponent as PrintArrow} from '../../assets/img/print.svg';
import { ReactComponent as BottomLogo} from '../../assets/img/developer.svg';
import PopupTicket from "../Popup/PopupTicket.js";
import ticketAction from "../../actions/ticket.action";
import PopupLogout from "../Popup/PopupLogout";
import EventBus from "../../constants/EventBus";
import PopupNameEntry from "../Popup/PopupNameEntry";
import popupTicketAction from "../../actions/popupTicket.action";
import loginAction from "../../actions/login.action";
import {Linear, TweenMax} from "gsap";
import {
    isEmpty,
    translateModuleText,
    translateText,
    translateTextComplete,
    translateTexts
} from "../../constants/utils";

class Ticket extends Component {

    constructor(props) {
        super(props);
        this.loading = false;
        this.pressHereButtonContainer = React.createRef();
        this.listenerDefined = false;
        this.appContentRef = React.createRef();
        this.dropdownElement = React.createRef();
        this.bottomContentRef = React.createRef();
        this.topContentRef = React.createRef();
        this.itemsToPrint = [];
        this.menuOpenState = true;
    }

    componentDidMount() {
        let { loginState, ticketAction, popupTicketAction} = this.props;
        // emanuel
       // loginState.config.print_button = "Asdasd"
        if (loginState.config.print_button === "instant") {
            EventBus.getInstance().addListener("instantPrint", this.listener = data => {

                let {ticketState} = this.props;
                let items = ticketState.items;

                if(data.popupSource === true){
                    popupTicketAction({
                        type: eventConstants.POPUP_OPEN_SELECT_TICKET_BACK
                    });

                    this.itemsToPrint.push(data.itemToPrint.id);

                    data.itemToPrint.selected  = true;
                    this.printNow([data.itemToPrint]);
                } else{
                    let obj = items[data.itemKey];
                    obj.selected = true;
                    this.printNow([obj]);
                }
            });
        }


        EventBus.getInstance().addListener("printTriggeredFromPopup", this.listener = data => {
            this.executePrint();
        });

        ticketAction({
            type: eventConstants.TIMER_START,
            timerName: "jobs_refresh",
            timerInterval: loginState.config.jobs_refresh * 1000,
            secondCall:{
                callType: eventConstants.TIMER_API,
                success: eventConstants.UPDATE_JOBS_CONTENT,
                fail: eventConstants.API_LIGHT_FAIL,
                url: loginState.config.jobs + loginState.loginAuth
            }
        });
    }

    componentWillUnmount() {
        let {loginState} = this.props;

        ticketAction({
            type: eventConstants.TIMER_STOP,
            timerName: "jobs_refresh"
        });

        if(loginState.config.print_button === "instant"){
            EventBus.getInstance().removeListener(this.listener);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {ticketState} = this.props;
        if (ticketState.dataReady === true && ticketState.total > 0) {
            if(!this.listenerDefined){
                this.listenerDefined = true;

                window.addEventListener("resize", this.resizeHeight.bind(this));
            }
            this.resizeHeight();

           if(this.menuOpenState === true){
               this.openCloseDropDown();
           }
        }
    }

    resizeHeight() {
        if (this.appContentRef.current) {
            try {
                let headingHeight = 0;
                if(document.querySelector(".heading-content") !== null){
                    headingHeight = document.querySelector(".heading-content").offsetHeight;
                }
                this.appContentRef.current.style.height = Math.round(window.innerHeight - headingHeight - this.topContentRef.current.offsetHeight - this.bottomContentRef.current.offsetHeight) + "px";
            } catch (e) { }
        }
    }

    renderBottomContent() {
        let {loginState} = this.props;
        if(loginState.config.switch !== null){
            return null;
        }
        return (
            <div className="app-developer">
                <div className="developer-content">
                    <span className="text">powered by</span>
                    <span className="developer-logo">
                                        <BottomLogo className="developer-svg" id="developer-svg"/>
                                     </span>
                </div>
            </div>
        );
    }

    renderPrintButton(){
        let {loginState, ticketState} = this.props;
        let texts = loginState.language[loginState.currentLang][loginState.config.type]["buttons"];

        if(loginState.config.print_button !== "instant"){
            return (
                <div className="print-ticket">
                    <button type="button"
                            className={ticketState.printEnabled?"":"disabledButton"}
                            disabled={!ticketState.printEnabled}
                            onClick={(e) => this.multiPrintPress(e)}
                    >
                                        <span className="icon">
                                           <PrintArrow/>
                                        </span>
                        <span className="texts">{translateTexts(texts, "print")}</span>
                    </button>
                </div>
            )
        }
        return null;
    }

    langButtonPressed(e){
        if(e) e.preventDefault();
        let {loginAction} = this.props;
        console.log(e.currentTarget.value);

        loginAction({
            type: eventConstants.CHANGE_LANGUAGE,
            lang: e.currentTarget.value
        });

    }

    openCloseDropDown(e) {
        if (e) e.preventDefault();

        if (this.dropdownElement.current) {
            if (this.menuOpenState === true) {
                this.menuOpenState = false;
                TweenMax.to(this.dropdownElement.current, 0.3,
                    {autoAlpha: 0, ease: Linear.easeNone});

            } else {
                this.menuOpenState = true;
                TweenMax.to(this.dropdownElement.current, 0.3,
                    {autoAlpha: 1, ease: Linear.easeNone});

            }
        }
    }

    renderLanguageSelector(){
        let {loginState} = this.props;

        let standByLangs = [];
        Object.getOwnPropertyNames(loginState.language).forEach(key => {
            let value = loginState.language[key];
            if (value.code !== loginState.currentLang) {
                standByLangs.push(value)
            }
        });

        if (loginState.config.language_selector === 1) {
            return (
                <div className="change-language">
                    <div className="language-dropdown">
                        <button type="button" onClick={(e) => this.openCloseDropDown(e)}>
                            <span className="texts">{loginState.currentLang}</span>
                        </button>
                        <div className="language-dropdown-menu" ref={this.dropdownElement}>
                            {standByLangs.map((item, key) =>
                                <button key={key} type="button" onClick={(e) => this.langButtonPressed(e)}
                                        value={item.code}>
                                    <span className="texts">{item.code}</span>
                                </button>
                            )}
                        </div>
                    </div>

                </div>
            );
        }

        return null;
    }

    multiPrintPress(e) {
        if (e) e.preventDefault();
        let {loginState, ticketState, ticketAction} = this.props;

        let items = ticketState.items;
        let printData = [];


        items.forEach(item => {
            if(item.selected === true){
                if(item.sub){
                    item.sub.forEach(subItem =>{
                        if(subItem.selected === true){
                            printData.push(subItem);
                        }
                    });
                } else{
                    printData.push(item);
                }
            }
        });

        this.printNow(printData);
    }


    singlePrintPress(e) {
        if (e) e.preventDefault();
        let {loginState, ticketState, ticketAction} = this.props;

        let items = ticketState.items;
        if(ticketState.screen === eventConstants.TICKET_PRINT_SCREEN_SHOW) {
            if (ticketState.total === 0) {
                this.printNow([items[0]])
            }
        }
    }

    printNow(pItems){
            let fnd = false;
            let idx;
            for (idx = 0; idx < pItems.length; idx++) {
                if (pItems[idx].extra === true) {
                    fnd = true;
                    break;
                }
            }


            this.itemsToPrint = [];
            for (idx = 0; idx < pItems.length; idx++) {
                this.itemsToPrint.push(pItems[idx].id);
            }

            if(fnd){
                let {loginState, ticketState, ticketAction} = this.props;

                ticketAction({
                    type: eventConstants.POPUP_OPEN_PRINT_TICKET,
                    tickets: pItems
                });
            }else{
                this.executePrint();
            }
    }

    executePrint(){
        let {loginState, ticketState, ticketAction} = this.props;

        ticketAction({
            type: globalConstants.API,
            success:eventConstants.SUCCESS_PRINT,
            fail: eventConstants.API_LIGHT_FAIL,
            url: loginState.config.action + loginState.loginAuth  + "&jobs=" + this.itemsToPrint.join()
        });
    }

    render() {
        let {ticketState, loginState, ticketAction} = this.props;
        if(ticketState.dataReady === false){
            return null;
        }

        let items = ticketState.items;
        if (ticketState.total === 0){
            if(items[0]){
                if(items[0].status === 0){
                    return (
                        <div>
                            <span>Empty content, ERROR </span>
                        </div>
                    );
                }
            }
        }

        if (ticketState.showPrinting === true) {

            // emanuel dev
            if(window.cordova){
                window.cordova.plugins.printer.print(ticketState.ticketPrintResponse.print.html, ticketState.ticketPrintResponse.print.options);
            }

            setTimeout(() => {
                ticketAction({
                    type: eventConstants.DONE_SHOWING_PRINT_SCREEN
                });
            }, 3000);
        }

        if (ticketState.screen === eventConstants.TICKET_PRINT_SCREEN_SHOW) {
            if (ticketState.total === 0) {
               // SINGLE

                let texts = loginState.language[loginState.currentLang][loginState.config.type]["texts"];
                let buttons = loginState.language[loginState.currentLang][loginState.config.type]["buttons"];

                return (
                    <Fragment>
                        <div className="top-content" ref={this.topContentRef}>
                            <div className="welcome">
                                <div className="welcome-content">
                                    <h1 className="title">{translateTexts(texts, "welcome")}</h1>
                                    <div className="logo">
                                        <img src={loginState.config.logo.square} alt="" className="smartq-logo" id="smartq-logo"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="smartq-app-get-ticket">
                            <div className="get-ticket-content">
                                <div className="title">{translateTexts(texts, "header")}</div>
                                <div className="press-here-element">
                                    <div className="press-here-element-content">
                                        <div className="left-arrow"></div>
                                        <div className="right-arrow"></div>

                                        <div className="press-here-button" ref={this.pressHereButtonContainer} >
                                            <div className="press-here-content" >
                                                <form onSubmit={this.singlePrintPress.bind(this)}>
                                                    <button className="press-here-content-inside" type="submit" >
                                                        <span className="texts press-here">{translateTexts(buttons, "print_single")}</span>
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <PopupTicket />
                        <PopupLogout />
                        <PopupNameEntry />
                    </Fragment>
                )
            } else if (ticketState.total > 0) {
                let useItems = [];
                for (let idx = 0; idx < items.length; idx++) {
                    if (isEmpty(items[idx].sub)) {
                        if (items[idx].name.hasOwnProperty(loginState.currentLang) === true) {
                            useItems.push(items[idx]);
                        }
                    }
                }

                let texts = loginState.language[loginState.currentLang][loginState.config.type]["texts"];
                return (
                    <Fragment>
                        <div className="top-content" ref={this.topContentRef}>
                            <div className="welcome">
                                <div className="welcome-content">
                                    <h1 className="title">{translateTexts(texts, "welcome")}</h1>
                                    <div className="logo">
                                        <img src={loginState.config.logo.square} alt="" className="smartq-logo" id="smartq-logo"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="smartq-app-content" ref={this.appContentRef}>
                            <div className="smartq-app-container">
                                <div className="buttons-section">
                                    {useItems.map((item, i) =>
                                        <TicketMainItem key={i} itemKey={i}/>
                                    )}
                                </div>
                            </div>
                        </div>



                        <div className="bottom-content" ref={this.bottomContentRef}>
                            <div className="print-and-language">
                                {this.renderPrintButton()}
                                {this.renderLanguageSelector()}
                            </div>

                            {this.renderBottomContent()}
                        </div>

                        <PopupTicket />
                        <PopupLogout />
                        <PopupNameEntry />

                    </Fragment>
                )
            }
        }
    }

}

const mapStateToProps = (state) => ({
    ticketState: state.ticketState,
    loginState: state.loginState
});

const mapDispatchToProps =  {
    ticketAction,
    popupTicketAction,
    loginAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Ticket);


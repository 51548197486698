import {combineReducers} from 'redux';
import * as multipleReducers from './app.reducer';
import operatorCounterCall from "./operatorCounterCall.reducer";
import adStateDetect from "./adStateDetect.reducer";
import loginState from "./loginState.reducer";
import newsFeedState from "./newsFeedState.reducer";
import clockState from "./clockState.reducer";
import ticketState from "./ticketState.reducer";
import operatorState from "./operatorState.reducer";
import operatorPopupStateDetect from "./operatorPopupStateDetect.reducer";
import popupTicketState from "./popupTicketState.reducer";
import popupLogoutState from "./popupLogoutState.reducer";
import queueState from "./queueState.reducer";
import popupNameEntryState from "./popupNameEntry.reducer";

const reducers = combineReducers(
    {
            loginState,
            newsFeedState,
            ticketState,
            popupTicketState,
            popupLogoutState,
             popupNameEntryState,
            operatorState,
            operatorPopupStateDetect,
            adStateDetect,
            queueState,
            clockState

            /*,
            ...multipleReducers,
            adStateDetect,
            operatorCounterCall*/
    });

export default reducers;
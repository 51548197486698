import {eventConstants, globalConstants} from "../constants";
import * as utils from "../constants/utils";
import {cloneDeep} from "lodash";

const initialState = {
    currentData: [],
    actions: [],
    blockRequests: false,

    skins: null,

    actionMode: false,
    currentAction: null,

    emptyMode: true,

    call: null
};

const queueState = (state = initialState, action) => {

    switch (action.type) {
        case eventConstants.EXECUTE_NEXT_QUEUE_ACTION:
            reducerName(state, action);

            let actions = cloneDeep(state.actions);
            let currentAction = {};

            if (actions.length > 0) {
                currentAction = cloneDeep(actions[0]);
                actions.shift();
            } else {
                return {
                    ...state,
                    actionMode: false,
                    blockRequests: false,
                    currentAction: null
                }
            }

            return {
                ...state,
                actionMode: true,
                actions: [...actions],
                currentAction
            };

        case eventConstants.UPDATE_QUEUE_DATA_EMPTY:
            reducerName(state, action);

            return {
                ...state,
                currentData: [
                    {
                        no: -1,
                        status: "empty",
                        id: -1,
                        device: ""
                    }
                ],
                currentAction: -1,
                emptyMode: false
            };

        case eventConstants.UPDATE_QUEUE_DATA:
            reducerName(state, action);

            let newData = cloneDeep(action.payload.currentData);
            let newActions = [];

            for (let j = 0; j < newData.length; j++) {
                if (newData[j].status !== "inwork" && newData[j].status !== "pending") {
                    newActions.push(newData[j]);
                }
            }

            return {
                ...state,
                currentData: cloneDeep(action.payload.currentData),
                actions: [...newActions],
                blockRequests: newActions.length !== 0,
                currentAction: -1,
                emptyMode: false
            };

        case eventConstants.QUEUE_SKIN_SUCCESS:
            reducerName(state, action);

            return {
                ...state,
                skins: {...action.payload.response}
            };

        default:
            return state;
    }
};

const reducerName = (state, action) => {
   // if (globalConstants.DEV_ENABLE_LOG === true) {
        console.log("--- REDUCER: queueState     ", action.type);
        console.log("state:  ", state);
        console.log("action:  ", action);
   // }
};

export default queueState;
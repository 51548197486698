import '../../assets/css/operator/operator.css';
import React, {Component, Fragment} from 'react';
import { connect} from "react-redux";
import {buttonsConstants, eventConstants, globalConstants, operatorPopupConstants} from "../../constants";
import { ReactComponent as PostponedIcon} from '../../assets/img/transfer.svg';
import { ReactComponent as DroppedIcon} from '../../assets/img/recall.svg';
import { ReactComponent as ManualIcon} from '../../assets/img/check.svg';
import { TweenMax, Power4 } from 'gsap';
import ChooseTicketItem from "./ChooseTicketItem";
import ChooseListItem from "./ChoooseListItem";
import operatorAction from "../../actions/operator.action";
import {isEmpty, translateText, translateTexts} from "../../constants/utils";

class ChooseTickets extends Component {

    constructor(props){
        super(props);

        this.nextButton = React.createRef();
        this.postponeButton = React.createRef();
        this.droppedButton = React.createRef();
        this.manualButton = React.createRef();

        this.DroppedTickets = React.createRef();
        this.myChooseList = React.createRef();
        this.myActionList = React.createRef();
        this.detailsArea = React.createRef();
        this.detailsAreaInput = React.createRef();


        this.myTween = null;
        this.myTween2 = null;
        this.myTweenChooseList = null;
        this.myTweenActionList = null;

        this.listenerDefined = false;
        this.firstLoad = true;

        this.textAreaTimeout = null;
        this.successTimeout = null;
    }

    static addClassToElement(pElem, pClass) {
        if (!isEmpty(pClass)) {
            let myCssSplit = pClass.split(" ");
            for (let idx = 0; idx < myCssSplit.length; idx++) {
                if (!pElem.classList.contains(myCssSplit[idx])) {
                    if (!isEmpty(myCssSplit[idx])) {
                        pElem.classList.add(myCssSplit[idx]);
                    }
                }
            }
        }
    }

    static removeClassFromElement(pElem, pClass) {
        if (!isEmpty(pClass)) {
            let myCssSplit = pClass.split(" ");
            for (let idx = 0; idx < myCssSplit.length; idx++) {
                if (!isEmpty(myCssSplit[idx])) {
                    if (pElem.classList.contains(myCssSplit[idx])) {
                        pElem.classList.remove(myCssSplit[idx]);
                    }
                }
            }
        }
    }

    buttonPress(e, butType){
        if(e) e.preventDefault();
        let {loginState, operatorAction } = this.props;

        if(butType !== buttonsConstants.NEXT_BUTTON){
            operatorAction({
                type: buttonsConstants.BUTTON_EVENT,
                butType: butType
            });
        } else if(butType === buttonsConstants.NEXT_BUTTON){
            operatorAction({
                type: eventConstants.OPERATOR_SERVER_CALL,
                subType: eventConstants.NEXT_ACTION_OPERATOR,
                success: eventConstants.NEXT_ACTION_SUCCESS,
                fail: null,
                butType: butType,
                popup: operatorPopupConstants.POPUP_TYPE_CONFIRMATION,
                popupSubType: operatorPopupConstants.POPUP_NEXT,
                popupRequired: loginState.config.popups.next,
                popupConfirmed: 0,
                url: loginState.config.actions.ticket_next + loginState.loginAuth
            });
        }
    }

    componentWillUnmount() {
        if (globalConstants.DISABLE_ANIMS === false) {
            try {
                TweenMax.killTweensOf(this.myTween);
                TweenMax.killTweensOf(this.myTween2);
                TweenMax.killTweensOf(this.myTweenChooseList);
                TweenMax.killTweensOf(this.myTweenActionList);
            } catch (e) {
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.firstLoad === true) {
            if (this.DroppedTickets.current) {
                this.firstLoad = false;
                TweenMax.set(this.DroppedTickets.current, {x: "-150%"});
            }
        }
        this.animatePanel();
        this.adjustScroller();

        let {operatorState, operatorAction} = this.props;
        if (operatorState.showSuccessNote === true) {
            ChooseTickets.addClassToElement(this.detailsArea.current, "success-shown");
            if (this.successTimeout) {
                clearInterval(this.successTimeout);
            }
            operatorAction({
                type: eventConstants.HIDE_NOTE_ACTION
            });

            this.successTimeout = setTimeout(() => {
                ChooseTickets.removeClassFromElement(this.detailsArea.current, "success-shown");
            }, 1500);
        }
    }

    animatePanel() {
        let {operatorState } = this.props;

        if (this.DroppedTickets.current) {
            if (operatorState.hideContent === true) {
                if(globalConstants.DISABLE_ANIMS === true){
                    TweenMax.set(this.DroppedTickets.current, {x:"-150%", autoAlpha:0, clearProps:"height"});
                    if(this.detailsArea.current){
                        TweenMax.set(this.detailsArea.current, {x:"-150%", autoAlpha:0});
                    }

                } else {
                    this.myTween = TweenMax.to(this.DroppedTickets.current, 0.6, {
                        x: "-150%",
                        autoAlpha: 0,
                        ease: Power4.easeOut,
                        clearProps: 'height' });

                    if(this.detailsArea.current){
                        this.myTween2 = TweenMax.to(this.detailsArea.current, 0.6, {
                            x: "-150%",
                            autoAlpha: 0,
                            ease: Power4.easeOut});
                    }

                }
            } else {
                if(globalConstants.DISABLE_ANIMS === true){
                    TweenMax.set(this.DroppedTickets.current, {x:"0%", autoAlpha:1});
                    if(this.detailsArea.current){
                        TweenMax.set(this.detailsArea.current, {x:"0%", autoAlpha:1});
                    }

                } else{
                    this.myTween = TweenMax.to(this.DroppedTickets.current, 0.6, { x: "0%", autoAlpha:1, ease: Power4.easeOut});
                    if(this.detailsArea.current){
                        this.myTween2 = TweenMax.to(this.detailsArea.current, 0.6,  { x: "0%", autoAlpha:1, delay:0.2, ease: Power4.easeOut});
                    }

                }
            }
        }

        if (this.myChooseList.current) {
            if(globalConstants.DISABLE_ANIMS === true){
                if (operatorState.status === globalConstants.OPERATOR_WAITING) {
                    TweenMax.set(this.myChooseList.current, {x:"50%", autoAlpha:0});
                    TweenMax.set(this.myActionList.current, {x:"0%", autoAlpha:1});
                    this.onCompleteHide();
                } else {
                    this.onStartShow();
                    TweenMax.set(this.myChooseList.current, {x:"-100%", autoAlpha:1});
                    TweenMax.set(this.myActionList.current, {x:"-150%", autoAlpha:0});
                }
            }else{
                if (operatorState.status === globalConstants.OPERATOR_WAITING) {
                    this.myTweenChooseList = TweenMax.to(this.myChooseList.current, 0.6, {
                        x: "50%",
                        autoAlpha: 0,
                        ease: Power4.easeOut,
                        onComplete: this.onCompleteHide.bind(this)
                    });
                    this.myTweenActionList = TweenMax.to(this.myActionList.current, 0.6, {
                        x: "0%",
                        autoAlpha: 1,
                        ease: Power4.easeOut
                    });
                } else {
                    this.myTweenChooseList = TweenMax.to(this.myChooseList.current, 0.6,
                        {x: "-100%", autoAlpha: 1, ease: Power4.easeOut, onStart: this.onStartShow.bind(this)});
                    this.myTweenActionList = TweenMax.to(this.myActionList.current, 0.6, {
                        x: "-150%",
                        autoAlpha: 0,
                        ease: Power4.easeOut
                    });
                }
            }
        }
    }

    onStartShow(){
        if(this.myChooseList.current){
            this.myChooseList.current.style.transform = "translate(110%,0)";
        }
    }

    onCompleteHide(){
        if(this.myChooseList.current){
            this.myChooseList.current.style.transform = "translate(-100%,0)";
        }
    }

    adjustScroller(){
        if (this.DroppedTickets.current) {
            if (!this.listenerDefined) {
                this.listenerDefined = true;
                window.addEventListener("resize", this.resizeHeight.bind(this));
            }
            this.resizeHeight();
        }
    }

    resizeHeight() {
        if (this.DroppedTickets.current) {
            try {
                var windowHeight = window.innerHeight;

                var logoTime = document.querySelector(".logo-time");
                var logoTimeHeight = logoTime.offsetHeight;

                var chooseTickets = document.querySelector(".choose-tickets");
                var chooseTicketsHeight = chooseTickets.offsetHeight;

                var droppedTickets = document.querySelector(".dropped-tickets");
                var droppedTicketsPaddingTop = ChooseTickets.returnValueForElement(droppedTickets, "padding-top");
                var droppedTicketsPaddingBottom = ChooseTickets.returnValueForElement(droppedTickets, "padding-bottom");
                var droppedTicketsMarginTop = ChooseTickets.returnValueForElement(droppedTickets, "margin-top");
                var droppedTicketsMarginBottom = ChooseTickets.returnValueForElement(droppedTickets, "margin-bottom");

                var noteHeight = 0;
                if(document.querySelector(".details-area")){
                    noteHeight = document.querySelector(".details-area").offsetHeight
                }
                this.DroppedTickets.current.style.height = ((windowHeight - logoTimeHeight - chooseTicketsHeight - droppedTicketsPaddingTop - droppedTicketsPaddingBottom - droppedTicketsMarginTop - droppedTicketsMarginBottom - 20) - noteHeight + "px");
            } catch (e) {

            }
        }
    }

    static returnValueForElement(pElement, pValue){
        return parseInt(window.getComputedStyle(pElement).getPropertyValue(pValue));
    }

    renderLeftSide() {
        return (
            <div className="left-side">
                <button type="button"
                        ref={this.nextButton}
                        onClick={(e) => this.buttonPress(e, buttonsConstants.NEXT_BUTTON)}>
                    <span className="texts">Next</span>
                </button>
            </div>
        )
    }

    getButtonClass(pType) {
        let {operatorState } = this.props;

        let composedButtonClass = "";
        if (pType === operatorState.activeButton) {
            composedButtonClass = "active";
        }

        if (this.getDisabledButtonStatus(pType) === true) {
            composedButtonClass += "disabled";
        }

        return composedButtonClass;
    }

    getDisabledButtonStatus(pType) {
        let {operatorState } = this.props;
        let perms = operatorState.perms;

        switch (pType) {
            case buttonsConstants.POSTPONED_BUTTON:
                if (perms.view_postponed !== 1) {
                    return true;
                }
                break;

            case buttonsConstants.DROPPED_BUTTON:
                if (perms.view_dropped !== 1) {
                    return true;
                }
                break;

            case buttonsConstants.MANUAL_BUTTON:
                if (perms.view_manual !== 1) {
                    return true;
                }
                break;

            default:
                return false;

        }
    }

    renderRightSide() {
        let {loginState,operatorState } = this.props;
        let texts = loginState.language[loginState.currentLang][loginState.config.type]["texts"];
        let buttons = loginState.language[loginState.currentLang][loginState.config.type]["buttons"];

        return (
                <div className="right-side">
                    <button type="button" ref={this.postponeButton}
                            onClick={(e) => this.buttonPress(e, buttonsConstants.POSTPONED_BUTTON)}
                            className={this.getButtonClass(buttonsConstants.POSTPONED_BUTTON)}
                            disabled={this.getDisabledButtonStatus(buttonsConstants.POSTPONED_BUTTON)}
                    >
                             <span className="icon">
                                   <PostponedIcon/>
                             </span>
                        <span className="texts">
                            {translateTexts(buttons, "postponed") + " (" + ChooseTickets.processTicketLengthNumber(operatorState.postponed.length) + ")"}
                        </span>
                    </button>

                    <button type="button" ref={this.droppedButton}
                            onClick={(e) => this.buttonPress(e, buttonsConstants.DROPPED_BUTTON)}
                            className={this.getButtonClass(buttonsConstants.DROPPED_BUTTON)}
                            disabled={this.getDisabledButtonStatus(buttonsConstants.DROPPED_BUTTON)}
                    >
                             <span className="icon">
                                   <DroppedIcon/>
                             </span>
                        <span className="texts">
                            {translateTexts(buttons, "dropped") + " (" + ChooseTickets.processTicketLengthNumber(operatorState.dropped.length) + ")"}
                        </span>
                    </button>

                    <button type="button" ref={this.manualButton}
                            onClick={(e) => this.buttonPress(e, buttonsConstants.MANUAL_BUTTON)}
                            className={this.getButtonClass(buttonsConstants.MANUAL_BUTTON)}
                            disabled={this.getDisabledButtonStatus(buttonsConstants.MANUAL_BUTTON)}
                    >
                             <span className="icon">
                                   <ManualIcon/>
                             </span>
                        <span className="texts">
                             {translateTexts(buttons, "manual") + " (" + ChooseTickets.processTicketLengthNumber(operatorState.manual.length) + ")"}
                        </span>
                    </button>
                </div>)
    }

    static processTicketLengthNumber(pNumber) {
        if (pNumber === 500) {
            return "500+";
        } else {
            return pNumber;
        }
    }

    createMarkup() {
        let {operatorState} = this.props;
        return {
            __html: operatorState.ticketHtml
        };
    }

    handleOnChangeTextArea(e){
        let textVal = e.target.value;
        let {operatorAction} = this.props;

        operatorAction({
            type: eventConstants.INPUT_FIELD_UPDATE_OPERATOR,
            textVal
        });

        if(this.textAreaTimeout){
            clearInterval(this.textAreaTimeout);
        }

        this.textAreaTimeout = setTimeout(() => {
            let {operatorAction, operatorState, loginState} = this.props;
            operatorAction({
                type: eventConstants.OPERATOR_SERVER_CALL,
                success: eventConstants.NOTE_ACTION_SUCCESS,
                fail: null,
                url: loginState.config.actions.ticket_note +
                    loginState.loginAuth + "&ticket=" +
                    operatorState.ticketNumber + "&note=" +
                    textVal
            });
        }, 500);
    }

    renderDetailArea() {
        let useArray;
        let {operatorState, loginState} = this.props;

        if (operatorState.activeButton === buttonsConstants.POSTPONED_BUTTON) {
            useArray = operatorState.postponed;
        } else if (operatorState.activeButton === buttonsConstants.DROPPED_BUTTON) {
            useArray = operatorState.dropped;
        } else if (operatorState.activeButton === buttonsConstants.MANUAL_BUTTON) {
            useArray = operatorState.manual;
        } else {
            useArray = operatorState.ticketInfo;
        }

        if (useArray) {
            if (operatorState.activeButton === buttonsConstants.NEXT_BUTTON) {
                /*let textAreaContent = "";

                if(!isEmpty(operatorState.inworkData)){
                    if(!isEmpty(operatorState.inworkData.note)){
                        textAreaContent = operatorState.inworkData.note
                    }
                }*/

                let workingText = loginState.language[loginState.currentLang][loginState.config.type].texts;

                return (
                    <Fragment>


                        <div className="dropped-tickets" ref={this.DroppedTickets}>
                            <div className="dropped-lists">
                                <div dangerouslySetInnerHTML={this.createMarkup()}/>
                            </div>

                        </div>

                        <div className="details-area" ref={this.detailsArea}>

                            <textarea className="details-area-input"
                                      placeholder={workingText.note}
                                      autoComplete="off"
                                      name=""
                                      value={operatorState.inputFieldValue}
                                      onChange={(event) => this.handleOnChangeTextArea(event)}>
                            </textarea>

                            <div className="notification">
                                <span>{workingText.note_update}</span>
                            </div>
                        </div>
                    </Fragment>


            )
            } else if (operatorState.activeButton !== "") {

                return (


                    <div className="dropped-tickets" ref={this.DroppedTickets}>
                        <div className="dropped-buttons">
                            {useArray.map((item, i) =>
                                <ChooseTicketItem key={i}
                                                  item={item}
                                                  config={this.props.config}
                                                  auth={this.props.auth}
                                                  buttonType={operatorState.activeButton}
                                />
                            )}
                        </div>

                    </div>


                )
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    renderChooseList(){
        let {operatorState } = this.props;

        return(
            <div className="choose-list" ref={this.myChooseList}>

               <ChooseListItem buttonType={buttonsConstants.TAKE_BUTTON}
                               ticketStatus={operatorState.ticketStatus}
                               ticketNumber={operatorState.ticketNumber}
                               config={this.props.config}
                               auth={this.props.auth}/>

               <ChooseListItem buttonType={buttonsConstants.TRANSFER_BUTTON}
                               ticketStatus={operatorState.ticketStatus}
                               ticketNumber={operatorState.ticketNumber}
                               config={this.props.config}
                               auth={this.props.auth}/>

               <ChooseListItem buttonType={buttonsConstants.DROP_BUTTON}
                               ticketStatus={operatorState.ticketStatus}
                               ticketNumber={operatorState.ticketNumber}
                               config={this.props.config}
                               auth={this.props.auth}/>

               <ChooseListItem buttonType={buttonsConstants.RECALL_BUTTON}
                               ticketStatus={operatorState.ticketStatus}
                               ticketNumber={operatorState.ticketNumber}
                               config={this.props.config}
                               auth={this.props.auth}/>

               <ChooseListItem buttonType={buttonsConstants.POSTPONE_BUTTON}
                               ticketStatus={operatorState.ticketStatus}
                               ticketNumber={operatorState.ticketNumber}
                               config={this.props.config}
                               auth={this.props.auth}/>

               <ChooseListItem buttonType={buttonsConstants.FINISH_BUTTON}
                               ticketStatus={operatorState.ticketStatus}
                               ticketNumber={operatorState.ticketNumber}
                               config={this.props.config}
                               auth={this.props.auth}/>
            </div>
        )
    }

    render() {
        return (
            <Fragment>
                <div className="choose-tickets">
                    <div className="action-list" ref={this.myActionList}>
                        {this.renderLeftSide()}
                        {this.renderRightSide()}
                    </div>
                    {this.renderChooseList()}
                </div>
                {this.renderDetailArea()}
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    operatorState: state.operatorState,
    loginState: state.loginState
});

const mapDispatchToProps =  {
    operatorAction
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseTickets);
import {eventConstants, globalConstants, operatorPopupConstants} from "../constants";

const initialState = {
    popupFormat: "",
    popupPayload: {},
    popupLifeEnded: true,

    transferActiveTab: 0,
    transferData:{},
    transferSelected: null,
    transferSelectedType: "",
    enableTransferButtons: false,
    transferText: ""
};

const operatorPopupStateDetect = (state = initialState, action) => {

    switch (action.type) {

        case eventConstants.LOGOUT_SUCCESS:
            reducerName(state, action);
            return {
                ...state,
                ...initialState
            };

        case eventConstants.RECALL_BUTTON_ACTION_SUCCESS:
            reducerName(state, action);
            return {
                ...state,
                popupLifeEnded: true
            };
        case eventConstants.RESUME_BREAK_ACTION_SUCCESS:
            reducerName(state, action);
            return {
                ...state,
                popupLifeEnded: true
            };

        case eventConstants.START_BREAK_ACTION_SUCCESS:
            reducerName(state, action);
            return {
                ...state,
                popupFormat: operatorPopupConstants.POPUP_ON_BREAK
            };

        case operatorPopupConstants.POPUP_ACTION_CANCEL:
            reducerName(state, action);
            return {
                ...state,
                popupLifeEnded: true
            };

        case operatorPopupConstants.TRANSFER_ITEM_SELECTED:
            reducerName(state, action);

            let idx = 0;
            let transferText = "";
            if (action.payload.transferSelectedType === "oid") {
                for (idx = 0; idx < state.transferData.operators.length; idx++) {
                    if (state.transferData.operators[idx].oid === action.payload.transferSelected) {
                        transferText = state.transferData.operators[idx].name;
                        break;
                    }
                }
            } else if (action.payload.transferSelectedType === "jid") {
                for (idx = 0; idx < state.transferData.jobs.length; idx++) {
                    if (state.transferData.jobs[idx].jid === action.payload.transferSelected) {
                        transferText = state.transferData.jobs[idx].name;
                        break;
                    }
                }
            }

            return {
                ...state,
                transferSelected: action.payload.transferSelected,
                transferSelectedType: action.payload.transferSelectedType,
                enableTransferButtons: true,
                transferText
            };


        case operatorPopupConstants.TRANSFER_TAB_SWITCH:
            reducerName(state, action);
            return {
                ...state,
                transferActiveTab: action.payload.transferActiveTab,
                transferSelected: null,
                enableTransferButtons: false
            };

        case eventConstants.TRANSFER_BUTTON_ACTION_SUCCESS:
            reducerName(state, action);
            return {
                ...state,
                popupLifeEnded: true
            };

        case eventConstants.TRANSFER_BUTTON_ACTION_SUCCESS_POPUP:
            reducerName(state, action);
            return {
                ...state,
                popupLifeEnded: false,
                popupFormat: operatorPopupConstants.POPUP_TYPE_TRANSFER,
                popupPayload:{...action.payload},
                transferActiveTab: 0,
                transferData: action.payload.response,
                transferSelected: null,
                enableTransferButtons: false,
            };

        case eventConstants.NEXT_ACTION_SUCCESS:
        case eventConstants.TAKE_BUTTON_ACTION_SUCCESS:
        case eventConstants.POSTPONE_BUTTON_ACTION_SUCCESS:
        case eventConstants.DROP_BUTTON_ACTION_SUCCESS:
        case eventConstants.FINISH_BUTTON_ACTION_SUCCESS:
            reducerName(state, action);
            return {
                ...state,
                popupLifeEnded: true
            };

        case operatorPopupConstants.SHOW_SETTINGS_POPUP:
            reducerName(state, action);
            return {
                ...state,
                popupLifeEnded:false,
                popupFormat: action.type
            };

        case operatorPopupConstants.POPUP_TYPE_TRANSFER:
            reducerName(state, action);
            return {
                ...state,
                popupLifeEnded:false,
                popupFormat: action.type,
                popupPayload:{...action.payload}
            };

        case operatorPopupConstants.POPUP_TYPE_CONFIRMATION:
            reducerName(state, action);
            return {
                ...state,
                popupLifeEnded:false,
                popupFormat: action.type,
                popupPayload:{...action.payload}
            };

        default:
            return state;
    }
};

const reducerName = (state, action) => {
    if (globalConstants.DEV_ENABLE_LOG === true) {
        console.log("--- REDUCER: clockState     ", action.type);
        console.log("state:  ", state);
        console.log("action:  ", action);
    }
};

export default operatorPopupStateDetect;
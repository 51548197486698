import '../../assets/css/dispenser/dispenser-popup.css';
import React, { Component } from 'react';
import { connect } from "react-redux";
import {eventConstants} from "../../constants";
import PopupTicketItem from "./PopupTicketItem";
import { ReactComponent as BackGraphic} from '../../assets/img/back.svg';
import { ReactComponent as CheckGraphic} from '../../assets/img/check.svg';
import {Linear, TweenMax} from "gsap";
import popupTicketAction from "../../actions/popupTicket.action";
import {isEmpty, translateText, translateTextAlternate, translateTexts} from "../../constants/utils";
import ticketAction from "../../actions/ticket.action";

class PopupTicket extends Component {

    constructor(props){
        super(props);

        this.theComponent = React.createRef();
        this.thePopup = React.createRef();
        this.myTween = null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.thePopup.current){
            let {popupTicketState} = this.props;
            if(popupTicketState.opened === true){
                this.myTween = TweenMax.to(this.theComponent.current, 0.2,
                    {autoAlpha:1, ease: Linear.easeNone});
            } else {
                this.myTween = TweenMax.to(this.theComponent.current, 0.2,
                    {autoAlpha:0, ease: Linear.easeNone});
            }
        }
    }

    backButtonPressed(e){
        if(e) e.preventDefault();
        let {popupTicketAction} = this.props;
        popupTicketAction({
            type: eventConstants.POPUP_OPEN_SELECT_TICKET_BACK
        });
    }

    doneButtonPressed(e){
        if(e) e.preventDefault();
        let {popupTicketState, ticketAction} = this.props;
        ticketAction({
            type: eventConstants.POPUP_OPEN_SELECT_TICKET_DONE,
            item: popupTicketState.item
        });

        this.backButtonPressed();
    }

    renderDoneButton(){
        let {loginState} = this.props;
        if(loginState.config.print_button === "instant"){
            return null;
        }else{
            return(
                <button className="done" type="button"
                        onClick={(e) => this.doneButtonPressed(e)}>
                                                    <span className="icon">
                                                        <CheckGraphic className="checked"/>
                                                    </span>
                    <span className="texts">{translateTexts(loginState.language[loginState.currentLang][loginState.config.type].popups.subjob.btn, "ok")}</span>
                </button>
            );
        }
    }

    render() {
        let {popupTicketState, loginState} = this.props;



        if(!isEmpty(popupTicketState.item)){
            let itemsSub = popupTicketState.item.sub;

            let useItems = [];
            for (let idx = 0; idx < itemsSub.length; idx++) {
                if (itemsSub[idx].name.hasOwnProperty(loginState.currentLang) === true) {
                    useItems.push(itemsSub[idx]);
                }
            }

            let parentTranslateObj = loginState.language[loginState.currentLang][loginState.config.type];
            if (!isEmpty(loginState.config.subtype)) {
                parentTranslateObj = loginState.language[loginState.currentLang][loginState.config.type][loginState.config.subtype];
            }

            let popupContent = translateTextAlternate(parentTranslateObj, "subjob", "title");
            popupContent = popupContent.replace("{JOB}", popupTicketState.item.name[loginState.currentLang]);

            return (
                <div className="smartq-popup" ref={this.theComponent}>
                    <div className="popup-overlay"></div>
                    <div className="popup-data">
                        <div className="popup-container">
                            <div className="popup-content">
                                <div className="container">
                                    <div className="inside-content">
                                        <div className="popup-dispenser-content" ref={this.thePopup}>
                                            <div className="popup-title">
                                                <div className="title">{popupContent}</div>
                                            </div>
                                            <div className="buttons-section">
                                                {useItems.map((item, i) =>
                                                    <PopupTicketItem key={i} itemKey={i}/>
                                                )}
                                            </div>

                                            <div className="action-buttons">
                                                <button className="back-action" type="button"
                                                        onClick={(e) => this.backButtonPressed(e)}>
                                                <span className="icon">
                                                    <BackGraphic className="back"/>
                                                </span>
                                                    <span className="texts">{translateTexts(loginState.language[loginState.currentLang][loginState.config.type].popups.subjob.btn, "cancel")}</span>
                                                </button>

                                                {this.renderDoneButton()}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => ({
    popupTicketState: state.popupTicketState,
    loginState: state.loginState
});

const mapDispatchToProps =  {
    popupTicketAction,
    ticketAction
};

export default connect(mapStateToProps, mapDispatchToProps)(PopupTicket);
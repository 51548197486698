import {buttonsConstants, eventConstants, globalConstants, operatorPopupConstants} from "../constants";


const queueAction = (payload) => {
    switch (payload.type) {


        case eventConstants.EXECUTE_NEXT_QUEUE_ACTION:
        case eventConstants.SWITCH_TO_ACTION_MODE:
        case eventConstants.UPDATE_QUEUE_DATA:
        case eventConstants.API_LIGHT_FAIL:
        case globalConstants.API:
        case eventConstants.TIMER_START:
            componentActionName(payload);
            return {
                type: payload.type,
                payload
            };

        default:
            return {
                type: payload.type,
                payload
            }
    }
};


const componentActionName = (payload) => {
    if (globalConstants.DEV_ENABLE_LOG === true) {
        console.log("--- ACTION: queueAction     ", payload.type);
        console.log("payload:  ", payload);
    }
};


export default queueAction;
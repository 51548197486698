import {buttonsConstants, eventConstants, globalConstants, globalData, operatorPopupConstants} from "../constants";
import {alertConstants} from "../constants";

export const adCall = (payload) => {
    console.log(">>>>>>>>>>>  ACTION:  adCall  <<<<<<<<< ", payload);

    if (payload.type === eventConstants.TIMER_START) {
        return {
            type: payload.type,
            payload
        };
    }

    if (payload.type === eventConstants.TIMER_STOP) {
        return {
            type: payload.type,
            payload
        };
    }

    if (payload.type === eventConstants.CALL_RECALL_EXECUTE) {
        return {
            type: payload.type,
            payload
        };
    }

    if (payload.type === eventConstants.RATE_NOW) {
        return {
            type: payload.type,
            payload
        };
    }

    if (payload.type === eventConstants.AD_RATING_TICKET_INFO_CALL) {
        return {
            type: payload.type,
            payload
        };
    }

    if (payload.type === eventConstants.AD_RATING_DATA_CALL) {
        return {
            type: payload.type,
            payload
        };
    }

    if (payload.type === eventConstants.AD_SERVER_CALL) {
        return {
            type: payload.type,
            payload
        };
    }

    if (payload.type === eventConstants.SHOW_NEXT_MEDIA_ITEM) {
        return {
            type: payload.type,
            payload
        };
    }


    if (payload.type === eventConstants.OPERATOR_SERVER_CALL) {
        return {
            type: payload.type,
            payload
        };
    }

    return {
        type: payload.type,
        payload
    };

};

export const operatorPopupCall = (payload) => {
      console.log(">>>>>>>>>>>  ACTION:  operatorPopupCall  <<<<<<<<< ", payload);

    if (payload.type === eventConstants.TRANSFER_ITEM_SELECTED) {
        return {
            type: payload.type,
            ...payload
        };
    }
    if (payload.type === eventConstants.TRANSFER_TAB_SWITCH) {
        return {
            type: payload.type,
            ...payload
        };
    }

    if (payload.type === eventConstants.OPERATOR_SERVER_CALL) {
        return {
            type: payload.type,
            payload
        };
    }

    return {
        type: payload.type,
        payload
    };

};

export const operatorCall = (payload) => {
    console.log(">>>>>>>>>>>  ACTION:  operatorCall  <<<<<<<<< ", payload);

    if (payload.type === eventConstants.POSTPONE_BUTTON_ACTION_OPERATOR) {
        return {
            type: payload.type,
            ...payload
        };
    }

    if (payload.type === eventConstants.ENABLE_RECALL) {
        return {
            type: payload.type,
            ...payload
        };
    }
    if (payload.type === eventConstants.DROP_BUTTON_ACTION_OPERATOR) {
        return {
            type: payload.type,
            ...payload
        };
    }

    if (payload.type === eventConstants.NEXT_ACTION_OPERATOR) {
        return {
            type: payload.type,
            ...payload
        };
    }

    if (payload.type === eventConstants.UPDATE_OPERATOR_CONTENT) {
        return {
            type: payload.type,
            ...payload
        };
    }

    if (payload.type === buttonsConstants.BUTTON_EVENT) {
        return {
            type: payload.type,
            ...payload
        };
    }

    if (payload.type === eventConstants.GET_TIME) {
        return {
            type: payload.type,
            ...payload
        };
    }

    return {
        type: payload.type,
        payload
    };

};

export const ticketPrintCall = (payload) => {
  //  console.log(">>>>>>>>>>>  ACTION:  ticketPrintCall  <<<<<<<<< ", payload);

    if (payload.type === eventConstants.PIN_NEW_CHAR_INPUT) {
        return {
            type: payload.type,
            payload
        };
    }
    if (payload.type === eventConstants.OPERATOR_SERVER_CALL) {
        return {
            type: payload.type,
            payload
        };
    }
    if (payload.type === eventConstants.DONE_SHOWING_PRINT_SCREEN) {
        return {
            type: payload.type,
            ...payload
        };
    }

    if (payload.type === eventConstants.PRINTING_TICKET_UPDATE_INFO) {
        return {
            type: payload.type,
            ...payload
        };
    }

    if (payload.type === eventConstants.POPUP_OPEN_SELECT_TICKET_DONE) {
        return {
            type: payload.type,
            ...payload
        };
    }


    if (payload.type === eventConstants.POPUP_OPEN_SELECT_TICKET_BACK) {
        return {
            type: payload.type,
            ...payload
        };
    }

    if (payload.type === eventConstants.POPUP_OPEN_SELECT_TICKET) {
        return {
            type: payload.type,
            ...payload
        };
    }

    if (payload.type === eventConstants.POPUP_OPEN_SELECT_TICKET_ITEM) {
        return {
            type: payload.type,
            ...payload
        };
    }

    if (payload.type === eventConstants.TICKET_PRINT_STATUS_RESET) {
        return {
            type: payload.type,
            ...payload
        };
    }

    if (payload.type === eventConstants.SELECT_TICKET_BUTTON) {
        return {
            type: payload.type,
            ...payload
        };
    }


    if (payload.type === eventConstants.UPDATE_TICKET_CONTENT) {
        return {
            type: payload.type,
            ...payload
        };
    }

    return {
        type: payload.type,
        payload
    };
};

export const animNewsCall = (payload) => {
 //  console.log(">>>>>>>>>>>  ACTION:  animNewsCall  <<<<<<<<< ", payload);

    if (payload.type === operatorPopupConstants.POPUP_PIN) {
        return {
            type: payload.type,
            ...payload
        };
    }

    if (payload.type === eventConstants.GET_TIME) {
        return {
            type: payload.type,
            ...payload
        };
    }

    if (payload.type === eventConstants.UPDATE_NEWS_CONTENT) {
        return {
            type: payload.type,
            ...payload
        };
    }

    if(payload.type === eventConstants.START_NEWS_TICKER_ANIMS){
        return {
            type: payload.type,
            ...payload
        }
    }

    if(payload.type === eventConstants.NEWS_TICKER_ANIM_COMPLETE){
        console.log("  f yeah !");
        return {
            type: payload.type,
            ...payload
        }
    }
    return {
        type: globalConstants.API,
        payload
    };
};

export const apiActionCall = (payload) => {
    console.log(">>>>>>>>>>>  ACTION:  apiActionCall  <<<<<<<<< ", payload);

    if(payload.subType === globalConstants.API_PING){
        let myLocalStoredUrl = localStorage.getItem(globalConstants.LOCAL_STORAGE_OBJ_PING);
        if(!myLocalStoredUrl && !payload.url){
            return {
                type: eventConstants.LAUNCH_SETUP,
                payload
            }
        } else {
           if(myLocalStoredUrl && !payload.url){
               payload.url = myLocalStoredUrl;
           }
        }
    }
   return {
        type: globalConstants.API,
        payload
    };
};


export const  storeSetupServerAddress = (payload) => {
  //  console.log(">>>>>>>>>>>  ACTION:  storeSetupServerAddress  <<<<<<<<< ", payload);

    if(payload.type === eventConstants.STORE){
        return {
            type: eventConstants.STORE,
            payload
        }
    }

    if(payload.type === eventConstants.SAVE_TO_LOCAL_STORAGE){
        localStorage.setItem(globalConstants.LOCAL_STORAGE_OBJ, payload.val);
        return {
            type: eventConstants.SAVE_TO_LOCAL_STORAGE,
            payload
        }
    }
};

export const sendLoginForm = (payload) => {
    let userValid = true;

    if(!payload.user){
        userValid = false;
    } else {
        if (payload.user.includes("@") && !testEmail(payload.user)) {
            userValid = false;
            return {
                type: alertConstants.ERROR,
                message: alertConstants.ERROR_EMAIL
            }
        }
    }

    if(!userValid){
        return {
            type: alertConstants.ERROR,
            message: alertConstants.ERROR_USER
        }
    }

    if(!payload.pass){
        return {
            type: alertConstants.ERROR,
            message: alertConstants.ERROR_PASS
        }
    }

    payload.formValid = true;
    localStorage.setItem(globalConstants.LOCAL_STORAGE_OBJ_USER, payload.user);
    localStorage.setItem(globalConstants.LOCAL_STORAGE_OBJ_PASS, payload.pass);

    return {
        type: globalConstants.API,
        payload: {
            url: globalData.API_BASE + globalConstants.API_DETECT + "?user=" + payload.user + "&pass=" + payload.pass,
            subType: eventConstants.LAUNCH_DETECT,
            success: eventConstants.SUCCESS_DETECT
        }

    }
};


export const onChangeLoginForm = (payload) => {
    return {
        type: eventConstants.FORM_CHANGE,
        payload
    }
};

const testEmail = email =>{
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
};


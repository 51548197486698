 import React, {Component} from 'react';
import { connect } from "react-redux";
import queueAction from "../../actions/queueAction";
import { ReactComponent as Arrow} from '../../assets/img/arrow.svg';
import { ReactComponent as Circle} from '../../assets/img/circle.svg';
import {isEmpty, translateText} from "../../constants/utils";
import Transition from "../../transition/Transition"
import EventBus from "../../constants/EventBus";
import {eventConstants} from "../../constants";
import {Howl, Howler} from "howler";
 import {cloneDeep} from "@babel/types";

class QueueCallItem extends Component {
    constructor(props) {
        super(props);

        this.myComponent = React.createRef();
        this.myTimeout = null;
        this.itemClass = "";
        this.playing = false;
        this.soundIter = -1;
        this.permitHide = false;
        this.mySettings = {};

        this.lastComponentStatus = "";

        Howler.volume(0.1);
    }

    playNextSound(){
        let {loginState, queueState, queueAction} = this.props;
        let currentSound = "";

        if (this.mySettings.audio === 1) {
            if (!isEmpty(queueState.currentAction.sound)) {
                if (this.playing === false) {
                    this.soundIter++;
                    if (this.soundIter < queueState.currentAction.sound.length) {
                        this.playing = true;
                        currentSound = queueState.currentAction.sound[this.soundIter];
                        console.log("PLAY:  ", currentSound);

                        let mySound = new Howl({
                            src: currentSound,
                            onplayererror: (()=>{
                                this.playing = false;
                                this.playNextSound();
                            }),
                            onloaderror:(()=>{
                                this.playing = false;
                                this.playNextSound();
                            }),
                            onend: (()=>{
                                mySound.unload();
                                this.playing = false;
                                this.playNextSound();
                            })
                        });

                        mySound.play();
                    } else{
                        this.finishedSounds();
                    }
                }
            } else{
                this.finishedSounds();
            }
        } else{
            this.finishedSounds();
        }
    }

    finishedSounds(){
        let {loginState, queueState, queueAction} = this.props;

        this.soundIter = -1;

        if(this.permitHide === true){
            this.actuallyHideNow();
        } else{
            clearTimeout(this.myTimeout);
            this.myTimeout = setTimeout(()=>{
                this.actuallyHideNow();
            }, this.mySettings.min_duration);
        }
    }

    actuallyHideNow(){
        let {loginState, queueState} = this.props;

        if(this.myComponent.current){
            this.myComponent.current.classList.add("main-ticket-reverse");
        }

        if (queueState.skins[loginState.devSkin][this.lastComponentStatus]) {
            setTimeout(() => {
                let mySettings = {};
                if(queueState.skins[loginState.devSkin][this.lastComponentStatus].style === "outside"){
                    mySettings = queueState.skins[loginState.devSkin][this.lastComponentStatus].outside;
                } else{
                    mySettings = queueState.skins[loginState.devSkin][this.lastComponentStatus].list;
                }
                EventBus.getInstance().fireEvent("REMOVE_CLASS_FROM_MAIN_SECOND_STAGE", {
                    className: mySettings.cssClass.app
                });

                this.myTimeout = setTimeout(()=>{
                    let {loginState, queueState, queueAction} = this.props;
                    queueAction({
                        type: eventConstants.EXECUTE_NEXT_QUEUE_ACTION
                    });
                }, 1000);


            }, queueState.skins[loginState.devSkin][this.lastComponentStatus].wait_after);
        }


    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.permitHide = false;

        this.myTimeout = setTimeout(()=>{
            this.permitHide = true;
        }, this.mySettings.min_duration);

        this.playNextSound();
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        let {loginState, queueState, queueAction} = nextProps;

        let itemValue = queueState.currentAction;

        console.log("---------- shouldComponentUpdate ");
        console.log(itemValue);
        console.log("  --");

        if (itemValue === null || itemValue === -1) {
            return false;
        } else {
            return true;
        }
    }

    render() {
        let {loginState, queueState} = this.props;

        if (queueState.currentAction === null || queueState.currentAction === -1) {

            return null;
        } else {
            let listConfig = queueState.skins[loginState.devSkin];

            this.mySettings = {};
            if (queueState.currentAction.status === "call") {
                this.mySettings = {...listConfig.call};
            } else if (queueState.currentAction.status === "recall") {
                this.mySettings = {...listConfig.recall};
            }

            if (this.mySettings.style !== "outside") {
                return null;
            }
        }

        if(this.myComponent.current){
            this.myComponent.current.classList.remove("main-ticket-reverse");
        }

        let itemValue = queueState.currentAction;

        let customClass = " " + this.mySettings.outside.cssClass.ticket;

        if(this.mySettings.outside.cssClass.app){
            EventBus.getInstance().fireEvent("ADD_CLASS_TO_MAIN_SECOND_STAGE", {
                className: this.mySettings.outside.cssClass.app
            });
        }

        let counterNumber = "";
        if(!isEmpty(itemValue.device)){
            counterNumber = itemValue.device;
        }

        this.itemClass = "tc-element ticket-bar-0"  + customClass;
        let textsLang = loginState.language[loginState.currentLang][loginState.config.type].texts;

        this.lastComponentStatus = queueState.currentAction.status;
        return (

            <Transition timeout={500} >
                <div className={"RAZVAN " + this.itemClass} ref={this.myComponent}>

                    <div className="first-side">
                        <div className="ticket-title-number">
                            <div className="ticket-title">{textsLang.ticket}</div>
                            <div className="ticket-number">{itemValue.no}</div>
                        </div>
                    </div>

                    <div className="ticket-text">{textsLang.go}</div>

                    <div className="second-side">
                        <div className="tc-svgs">
                            <Arrow className="tc-arrow"/>
                            <Circle className="tc-dot"/>
                        </div>
                        <div className="counter-title-number">
                            <div className="counter-title">{ textsLang.counter}</div>
                            <div className="counter-number">{counterNumber}</div>
                        </div>
                    </div>
                </div>
            </Transition>
        );
    }


    componentWillUnmount() {
        clearTimeout(this.myTimeout);
    }
}

const mapStateToProps = (state) => ({
    loginState: state.loginState,
    queueState: state.queueState
});

const mapDispatchToProps = {
    queueAction
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(QueueCallItem);
import '../../assets/css/operator/operator-counter.css';
import React, { Component } from 'react';
import { connect} from "react-redux";
import {adCall, ticketPrintCall} from "../../actions";
import {eventConstants, globalData} from "../../constants";
import {Linear, Power4, TweenMax} from "gsap";
import {ReactComponent as RatingIcon} from "../../assets/img/rating.svg";
import adAction from "../../actions/adAction";

class OperatorCounterCall extends Component {

    constructor(props){
        super(props);
        this.callingCounter = React.createRef();
        this.ticketElement = React.createRef();
        this.myTween = null;

        this.blinkIncrement = 0;
        this.blinkTimer = null;
        this.waitBlinkTimeout = null;
        this.blinkTimes = null;
        this.blinkRate = null;
        this.permitBlink = true;
    }

    componentDidMount() {

        let{loginState, adAction} = this.props;

        if(loginState.config.subtype === "counter"){
            adAction({
                type: eventConstants.TIMER_START,
                timerName: "counter_feed",
                timerInterval: loginState.config.ticket_refresh * 1000,
                secondCall:{
                    callType: eventConstants.TIMER_API,
                    success: eventConstants.AD_TICKET_CALL_SUCCESS,
                    fail: eventConstants.API_LIGHT_FAIL,
                    url: loginState.config.ticket + loginState.loginAuth,
                }
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {loginState, adStateDetect} = this.props;
        if(this.callingCounter.current){
            if(this.myTween){
                TweenMax.killTweensOf(this.myTween);
            }

            if(adStateDetect.showTicketPanel === true){
                this.myTween = TweenMax.to(this.callingCounter.current, 1, {autoAlpha: 1, ease: Power4.easeNone});
                if(adStateDetect.ticketStatus === 2 || adStateDetect.ticketStatus === 3){
                    this.resetBlinkStatus();
                    this.blinkTimer = setInterval(()=>{
                        this.blinkNow(loginState);
                    }, loginState.config.alert.blink.speed);
                }
            } else{
                this.resetBlinkStatus();
                this.myTween = TweenMax.to(this.callingCounter.current, 1, {autoAlpha: 0, ease: Power4.easeNone});
            }
        }
    }

    resetBlinkStatus(){
        if(this.blinkTimer){
            clearInterval(this.blinkTimer);
        }
        if(this.waitBlinkTimeout){
            clearTimeout(this.waitBlinkTimeout);
        }
        this.blinkTimes = 0;
        this.blinkIncrement = 0;
        this.permitBlink = true;

        if(this.ticketElement.current){
            this.ticketElement.current.style.opacity = 1;
        }
    }

    blinkNow(loginState) {
        if(this.permitBlink === true){
            this.blinkIncrement++;

            if (this.blinkIncrement % 2 === 0) {
                this.ticketElement.current.style.opacity = 0;
            } else {
                this.ticketElement.current.style.opacity = 1;
            }

            if(this.blinkIncrement >= loginState.config.alert.blink.rate){
                this.blinkIncrement = 0;
                this.permitBlink = false;
                this.ticketElement.current.style.opacity = 1;
                this.waitBlinkTimeout = setTimeout(() =>{
                    if(this.blinkTimes < loginState.config.alert.blink.times){
                        this.permitBlink = true;
                        this.blinkTimes++;
                    } else {
                        clearInterval(this.blinkTimer);
                    }
                }, loginState.config.alert.blink.pause)
            }
        }
    }


    render() {
        let {loginState, adStateDetect} = this.props;

        if(loginState.config.subtype !== "counter"){
            return null;
        }

        return (
            <div className="landscape">
                <div className="operator-calling-counter" ref={this.callingCounter}>
                    <div className="smartq-app landscape">
                        <div className="title-operator-counter">
                            <div className="title">{adStateDetect.ticketMsg}</div>
                        </div>

                        <div className="smartq-operator-counter">
                            <div className="smartq-operator-counter-content"
                                 ref={this.ticketElement}>{adStateDetect.ticket}</div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    adStateDetect: state.adStateDetect,
    loginState: state.loginState
});

const mapDispatchToProps =  {
    adAction
};


export default connect(mapStateToProps, mapDispatchToProps)(OperatorCounterCall);

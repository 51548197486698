import '../../assets/css/dispenser/dispenser-popup.css';
import React, { Component } from 'react';
import { connect } from "react-redux";
import {  operatorPopupCall } from "../../actions";
import {eventConstants, globalConstants, operatorPopupConstants} from "../../constants";
import { ReactComponent as TakeIcon} from '../../assets/img/take.svg';
import { ReactComponent as CloseIcon} from '../../assets/img/close.svg';
import { ReactComponent as PostponeIcon} from '../../assets/img/postpone.svg';
import { ReactComponent as LogoutIcon} from '../../assets/img/logout.svg';
import {Power4, TweenMax} from "gsap";
import PopupOperatorTransferItem from "./PopupOperatorTransferItem";
import {isEmpty, translateTextPopup} from "../../constants/utils";
import operatorAction from "../../actions/operator.action";

class PopupOperator extends Component {

    constructor(props){
        super(props);

        this.theComponent = React.createRef();
        this.buttonConfirm = React.createRef();
        this.buttonCancel = React.createRef();
        this.detailsInput = React.createRef();
        this.prio = 0;
        this.myTween = null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.theComponent.current) {
            let componentData = this.props.componentData;

            if (globalConstants.DISABLE_ANIMS === true) {
                if (componentData.popupLifeEnded === false) {
                    document.querySelector("html").classList.add("popup-active");
                    TweenMax.set(this.theComponent.current, {autoAlpha: 1});
                } else {
                    document.querySelector("html").classList.remove("popup-active");
                    TweenMax.set(this.theComponent.current, {autoAlpha: 0});
                }
            } else {
                if (componentData.popupLifeEnded === false) {
                    document.querySelector("html").classList.add("popup-active");
                    this.myTween = TweenMax.to(this.theComponent.current, 0.6, {autoAlpha: 1, ease: Power4.easeOut});
                } else {
                    document.querySelector("html").classList.remove("popup-active");
                    this.myTween = TweenMax.to(this.theComponent.current, 0.6, {autoAlpha: 0, ease: Power4.easeOut});
                }
            }
        }
    }

    buttonPressConfirm(e) {
        if (e) e.preventDefault();
        let componentData = this.props.componentData;
        let popupPayload = componentData.popupPayload;

        this.props.operatorPopupCall({
            type: popupPayload.type,
                ...popupPayload,
                popupConfirmed: 1,
                note: componentData.popupPayload.popupSubType === operatorPopupConstants.POPUP_TRANSFER ? this.detailsInput.current.value : null
        });
    }

    buttonPressCancel(e) {
        if (e) e.preventDefault();
        let componentData = this.props.componentData;
        let popupPayload = componentData.popupPayload;

        if (popupPayload.popupSubType === operatorPopupConstants.POPUP_RESUME) {
            this.props.operatorPopupCall({
                type: eventConstants.START_BREAK_ACTION_SUCCESS
            });
        } else if (popupPayload.popupSubType === operatorPopupConstants.POPUP_BREAK ||
            popupPayload.popupSubType === operatorPopupConstants.POPUP_LOGOUT ||
            popupPayload.popupSubType === operatorPopupConstants.POPUP_ACTION_EXIT) {
            this.props.operatorAction({
                type: operatorPopupConstants.SHOW_SETTINGS_POPUP
            })
        } else {
            this.props.operatorPopupCall({
                type: popupPayload.type,
                ...popupPayload,
                popupConfirmed: -1
            });
        }
    }

    buttonPressCloseTransfer(e) {
        if (e) e.preventDefault();
        this.props.operatorPopupCall({
            type: operatorPopupConstants.POPUP_ACTION_CANCEL
        });
    }

    buttonPressBackOptions(e){
        if (e) e.preventDefault();
        this.props.operatorPopupCall({
            type: operatorPopupConstants.POPUP_ACTION_CANCEL
        });
    }

    buttonPressBreak(e){
        if (e) e.preventDefault();
        let {loginState} = this.props;

        this.props.operatorPopupCall({
            type: eventConstants.OPERATOR_SERVER_CALL,
            success: eventConstants.START_BREAK_ACTION_SUCCESS,
            fail: null,
            butType: "",
            popup:  operatorPopupConstants.POPUP_TYPE_CONFIRMATION,
            popupSubType: operatorPopupConstants.POPUP_BREAK,
            popupConfirmed: 0,
            popupRequired: loginState.config.popups.break,
            url: loginState.config.actions.operator_break + loginState.loginAuth
        });

    }

    buttonPressResumeWork(e){
        if (e) e.preventDefault();

        let {loginState} = this.props;

        this.props.operatorPopupCall({
            type: eventConstants.OPERATOR_SERVER_CALL,
            success: eventConstants.RESUME_BREAK_ACTION_SUCCESS,
            fail: null,
            butType: "",
            popup:  operatorPopupConstants.POPUP_TYPE_CONFIRMATION,
            popupSubType: operatorPopupConstants.POPUP_RESUME,
            popupConfirmed: 0,
            popupRequired: loginState.config.popups.resume,
            url: loginState.config.actions.operator_resume + loginState.loginAuth
        });

    }

    renderConfirmationPopup(){
        let componentData = this.props.componentData;
        if (componentData.popupFormat === operatorPopupConstants.POPUP_TYPE_CONFIRMATION) {
            let {loginState, operatorState} = this.props;
            let title, body, btnOk, btnCancel;

            let confirmedMode = "";
            switch (componentData.popupPayload.popupSubType) {
                case operatorPopupConstants.POPUP_NEXT:
                    confirmedMode = "next";
                    break;
                case operatorPopupConstants.POPUP_POSTPONED_BUTTON:
                    confirmedMode = "call";
                    break;
                case operatorPopupConstants.POPUP_TAKE:
                    confirmedMode = "take";
                    break;
                case operatorPopupConstants.POPUP_DROP:
                    confirmedMode = "drop";
                    break;
                case operatorPopupConstants.POPUP_POSTPONE:
                    confirmedMode = "postpone";
                    break;
                case operatorPopupConstants.POPUP_FINISH:
                    confirmedMode = "finish";
                    break;
                case operatorPopupConstants.POPUP_TRANSFER:
                    confirmedMode = "transfer";
                    break;
                case operatorPopupConstants.POPUP_RESUME:
                    confirmedMode = "resume";
                    break;
                case operatorPopupConstants.POPUP_LOGOUT:
                    confirmedMode = "logout";
                    break;
                case operatorPopupConstants.POPUP_ACTION_EXIT:
                    confirmedMode = "exit";
                    break;

                case operatorPopupConstants.POPUP_RECALL:
                    confirmedMode = "recall";
                    break;
                case operatorPopupConstants.POPUP_BREAK:
                    confirmedMode = "break";
                    break;
                case operatorPopupConstants.POPUP_ON_BREAK:
                    confirmedMode = "break";
                    break;
                default:
                    console.log(" no popup type provided ! ");
            }


            if (confirmedMode === "transfer") {
                if(this.prio === 0){
                   confirmedMode = "transfer_normal";
                } else if(this.prio === 1){
                    confirmedMode = "transfer_prio";
                }

                let workingText = loginState.language[loginState.currentLang][loginState.config.type].popups[confirmedMode].body;
                workingText = workingText.replace("{TICKET}", operatorState.ticketNumber);
                workingText = workingText.replace("{DEST}", componentData.transferText);
                body = workingText;
            } else {
                body = translateTextPopup(loginState, confirmedMode, "body");
                body = body.replace("{TICKET}", componentData.popupPayload.ticketNoRequested);
            }

            title = translateTextPopup(loginState, confirmedMode, "title");
            title = title.replace("{TICKET}", componentData.popupPayload.ticketNoRequested);

            btnOk = translateTextPopup(loginState, confirmedMode, "btn", "ok");
            btnCancel = translateTextPopup(loginState, confirmedMode, "btn", "cancel");

            if(isEmpty(title)){
                title = "";
            }

            if(isEmpty(body)){
                body = "";
            }

            if(isEmpty(btnOk)){
                btnOk = "";
            }

            if(isEmpty(btnCancel)){
                btnCancel = "";
            }

            return (
                <div className="popup-confirm-cancel">
                    <div className="parent-icon">
                        {this.renderConfirmationPopupIcon()}
                    </div>
                    <div className="title">{title}</div>
                    <div className="guidance">{body}</div>
                    {this.renderDetailsField()}
                    <div className="action-buttons">
                        {this.renderOkButton(btnOk)}
                        {this.renderCancelButton(btnCancel)}
                    </div>
                </div>
            )
        }
    }

    renderDetailsField() {
        let componentData = this.props.componentData;
        let {loginState, operatorState} = this.props;
        let textsLang = loginState.language[loginState.currentLang][loginState.config.type].texts;

        if (componentData.popupPayload.popupSubType === operatorPopupConstants.POPUP_TRANSFER) {
            return <textarea className="transfer-input" type="text" placeholder={textsLang.transfer} name=""
                             ref={this.detailsInput}></textarea>
        }
        return null;
    }

    renderOkButton(pValue){
        let componentData = this.props.componentData;
        if(!isEmpty(pValue)){
            return (
                <button className="confirm-action" type="button" ref={this.buttonConfirm}
                        disabled={componentData.popupLifeEnded}
                        onClick={(e) => this.buttonPressConfirm(e)}>
                    <span className="texts">{pValue}</span>
                </button>
            )
        } else{
            return null;
        }
    }

    renderCancelButton(pValue){
        let componentData = this.props.componentData;
        if(!isEmpty(pValue)){
            return (
                <button className="cancel-action" type="button" ref={this.buttonCancel}
                        disabled={componentData.popupLifeEnded}
                        onClick={(e) => this.buttonPressCancel(e)}>
                    <span className="texts">{pValue}</span>
                </button>
            )
        } else{
            return null;
        }
    }

    renderConfirmationPopupIcon(){
        let componentData = this.props.componentData;
        let popupPayload = componentData.popupPayload;
        switch (popupPayload.popupSubType) {
            case operatorPopupConstants.POPUP_NEXT:
                return <TakeIcon/>;
            default:
                return null;
        }
    }

    getTransferActiveTab(pTab) {
        let componentData = this.props.componentData;
        if (pTab === "operator") {
            if (componentData.transferActiveTab === 0) {
                return "for-tab active";
            } else {
                return "for-tab"
            }
        } else if (pTab === "jobs") {
            if (componentData.transferActiveTab === 1) {
                return "for-tab active";
            } else {
                return "for-tab"
            }
        }
    }

    buttonPressTab(e, pTab){
        if(e) e.preventDefault();
        let componentData = this.props.componentData;
        if(componentData.transferActiveTab !== pTab){
            this.props.operatorPopupCall({
                type: operatorPopupConstants.TRANSFER_TAB_SWITCH,
                transferActiveTab: pTab
            });
        }
    }

    buttonPressTransfer(e, pPriority){
        if(e) e.preventDefault();
        this.prio = pPriority;
        let componentData = this.props.componentData;
        let {loginState} = this.props;
        this.props.operatorPopupCall({
            type: eventConstants.OPERATOR_SERVER_CALL,
            subType: eventConstants.TRANSFER_BUTTON_ACTION_OPERATOR,
            success: eventConstants.TRANSFER_BUTTON_ACTION_SUCCESS,
            fail: null,
            butType: "",
            popup:  operatorPopupConstants.POPUP_TYPE_CONFIRMATION,
            popupSubType: operatorPopupConstants.POPUP_TRANSFER,
            popupConfirmed: 0,
            popupRequired: loginState.config.popups.transfer,
            url: loginState.config.actions.ticket_transfer +
                loginState.loginAuth +
                "&ticket=" + componentData.popupPayload.deepPayload.ticketNumber +
                "&" + componentData.transferSelectedType + "=" + componentData.transferSelected +
                "&prio=" + this.prio
        });
    }

    buttonPressLogout(e){
        if(e) e.preventDefault();
        let {loginState} = this.props;

        this.props.operatorPopupCall({
            type: eventConstants.OPERATOR_SERVER_CALL,
            success: eventConstants.LOGOUT_SUCCESS,
            fail: null,
            butType: "",
            popup:  operatorPopupConstants.POPUP_TYPE_CONFIRMATION,
            popupSubType: operatorPopupConstants.POPUP_LOGOUT,
            popupConfirmed: 0,
            popupRequired: loginState.config.popups.logout,
            url: loginState.config.logout + loginState.loginAuth
        });

    }

    buttonPressExit(e){
        if(e) e.preventDefault();
        let {loginState} = this.props;

        this.props.operatorPopupCall({
            type: eventConstants.OPERATOR_ACTION,
            subType: eventConstants.TRANSFER_BUTTON_ACTION_OPERATOR,
            success: eventConstants.POPUP_ACTION_EXIT_SUCCESS,
            fail: null,
            butType: "",
            popup:  operatorPopupConstants.POPUP_TYPE_CONFIRMATION,
            popupSubType: operatorPopupConstants.POPUP_ACTION_EXIT,
            popupConfirmed: 0,
            popupRequired: loginState.config.popups.exit
        });
    }

    renderExitButton(){
      //  if (window.cordova){
            let {loginState} = this.props;
            return (
                <button onClick={(e) => this.buttonPressExit(e)} >
                    <div className="icon">
                        <LogoutIcon/>
                    </div>
                    <span className="texts">{translateTextPopup(loginState, "options", "btn", "exit")}</span>
                </button>
            )
       /* } else {
            return null;
        }*/
    }

    getActionButtonsDisableStatus(useBoolean = false) {
        let componentData = this.props.componentData;
        if (componentData.enableTransferButtons === false) {
            return useBoolean ? true : " disabled";
        } else {
            return useBoolean ? false : "";
        }
    }

    renderTransferPopup(){
        let componentData = this.props.componentData;
        if (componentData.popupFormat === operatorPopupConstants.POPUP_TYPE_TRANSFER) {
            let currentDataSet = [];
            if(componentData.transferActiveTab === 0){
                currentDataSet = componentData.transferData.operators;
            } else if(componentData.transferActiveTab === 1){
                currentDataSet = componentData.transferData.jobs;
            }

            return (
                <div className="popup-transfer-tickets">
                    <div className="popup-trigger">
                        <CloseIcon className="close-svg" onClick={(e) => this.buttonPressCloseTransfer(e)}/>

                    </div>
                    <div className="popup-title">
                        <div className="title">Transfer Ticket</div>
                    </div>
                    <div className="tabs-for-ticketsList">
                        <a className={this.getTransferActiveTab("operator")}
                           onClick={(e) => this.buttonPressTab(e, 0)}>Operator</a>
                        <a className={this.getTransferActiveTab("jobs")}
                           onClick={(e) => this.buttonPressTab(e, 1)}>Jobs</a>
                    </div>
                    <div className="tickets-list">
                        <div className="tickets-list-content">
                            {currentDataSet.map((item, i) =>
                                <PopupOperatorTransferItem key={i} item={item} transferSelected={componentData.transferSelected}  />
                            )}
                        </div>

                    </div>

                    <div className="action-buttons">

                        <button className={"priority-action" + this.getActionButtonsDisableStatus()} type="button"
                                disabled={this.getActionButtonsDisableStatus(true)}
                                onClick={(e) => this.buttonPressTransfer(e, 1)}
                        >
                            <span className="texts">Priority</span>
                        </button>

                        <button className={"normal-action" + this.getActionButtonsDisableStatus()} type="button"
                                disabled={this.getActionButtonsDisableStatus(true)}
                                onClick={(e) => this.buttonPressTransfer(e, 0)}
                        >
                            <span className="texts">Normal</span>
                        </button>

                    </div>

                </div>
            )
        }
    }

    renderSettingsPopup(){
        let componentData = this.props.componentData;
        let {loginState} = this.props;
        if (componentData.popupFormat === operatorPopupConstants.SHOW_SETTINGS_POPUP) {

            return (
                <div className="popup-operator-content">

                    <div className="popup-title">
                        <div className="title">{translateTextPopup(loginState, "options", "title")}</div>
                    </div>

                    <div className="buttons-section">
                        <button
                            onClick={(e) => this.buttonPressBreak(e)}
                        >
                            <div className="icon">
                                <PostponeIcon/>
                            </div>
                            <span className="texts">{translateTextPopup(loginState, "options", "btn", "break")}</span>
                        </button>
                        <button
                            onClick={(e) => this.buttonPressLogout(e)}
                        >
                            <div className="icon">
                                <LogoutIcon/>
                            </div>
                            <span className="texts">{translateTextPopup(loginState, "options", "btn", "logout")}</span>
                        </button>

                        {this.renderExitButton()}
                    </div>

                    <div className="action-buttons">
                        <button className="back-action" type="button"
                                onClick={(e) => this.buttonPressBackOptions(e)}
                        >
                            <span className="texts">{translateTextPopup(loginState, "options", "btn", "cancel")}</span>
                        </button>
                    </div>
                </div>
            )
        }
    }

    renderOnBreak(){
        let componentData = this.props.componentData;
        if (componentData.popupFormat === operatorPopupConstants.POPUP_ON_BREAK) {

            return (
                <div className="popup-resume">
                    <div className="parent-icon">
                        <PostponeIcon/>
                    </div>
                    <div className="title">You are momentarily on a break</div>
                    <div className="guidance">Continue by pressing resume</div>

                    <div className="action-buttons">
                        <button className="resume-action" type="button"
                                onClick={(e) => this.buttonPressResumeWork(e)}
                        >
                            <span className="texts">Resume</span>
                        </button>
                    </div>
                </div>
            )
        }
    }
    render() {
         return (
            <div className="smartq-popup" ref={this.theComponent}>
                <div className="popup-overlay"></div>
                <div className="popup-data">
                    <div className="popup-container">
                        <div className="popup-content">
                            <div className="container">
                                <div className="inside-content">
                                    {this.renderSettingsPopup()}
                                    {this.renderOnBreak()}
                                    {this.renderConfirmationPopup()}
                                    {this.renderTransferPopup()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    componentData: state.operatorPopupStateDetect,
    operatorState: state.operatorState,
    loginState: state.loginState
});

const mapDispatchToProps =  {
    operatorPopupCall,
    operatorAction
};


export default connect(mapStateToProps, mapDispatchToProps)(PopupOperator);



/* this.props.operatorPopupCall({
     type: eventConstants.OPERATOR_SERVER_CALL,
     success: eventConstants.RESUME_BREAK_ACTION_SUCCESS,
     fail: null,
     popupRequired: 0,
     url: loginState.config.actions.operator_resume + loginState.loginAuth
 });*/



/*   this.props.operatorPopupCall({
       type: eventConstants.OPERATOR_SERVER_CALL,
       success: eventConstants.LOGOUT_SUCCESS,
       fail: null,
       url: loginState.config.logout + loginState.loginAuth
   });*/



/* this.props.operatorPopupCall({
     type: eventConstants.OPERATOR_SERVER_CALL,
     success: eventConstants.START_BREAK_ACTION_SUCCESS,
     fail: null,
     popup: operatorPopupConstants.POPUP_TYPE_CONFIRMATION,
     popupSubType: operatorPopupConstants.POPUP_FINISH,
     popupRequired: 0,
     url: loginState.config.actions.operator_break + loginState.loginAuth
 });*/
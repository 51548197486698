import {buttonsConstants, eventConstants, operatorPopupConstants} from "../constants";
import { alertConstants, globalConstants } from "../constants";
import {cloneDeep} from 'lodash';
import * as utils from "../constants/utils";

let getLocalUserData = (pField) => {
    let localData = localStorage.getItem(pField);
    if(localData){
        return localData;
    }
    return "";
};

const appState = {
    screen: eventConstants.LAUNCH_LOGIN
};

export const appStateDetect = (state = appState, action) => {
   console.log(" [[[[[[[[[  REDUCER: appStateDetect  ]]]]]]]]] ", action);

    switch (action.type) {
        case eventConstants.LOGOUT_SUCCESS:
            return {
                ...state,
                ...appState
            };

        case eventConstants.LAUNCH_ADS:
            console.log("                               case eventConstants.LAUNCH_SCREEN_TICKET:");
            return {
                ...state,
                payload: action.payload,
                screen: eventConstants.LAUNCH_ADS
            };

        case eventConstants.LAUNCH_OPERATOR:
            console.log("                               case eventConstants.LAUNCH_SCREEN_TICKET:");
            return {
                ...state,
                payload: action.payload,
                screen: eventConstants.LAUNCH_OPERATOR
            };

        case eventConstants.LAUNCH_SCREEN_TICKET:
            console.log("                               case eventConstants.LAUNCH_SCREEN_TICKET:");
            return {
                ...state,
                payload: action.payload,
                screen: eventConstants.LAUNCH_SCREEN_TICKET
            };

        default:
            return state;
    }
};










const recallState = {
    iterations: 0,
    recallEnabled: true,
    killTimeout: false
};

export const recallStateDetect = (state = recallState, action) => {
    console.log(" [[[[[[[[[  REDUCER: recallStateDetect  ]]]]]]]]] ", action);

    switch (action.type) {

        case eventConstants.LOGOUT_SUCCESS:
            return {
                ...state,
                ...recallState
            };
        case eventConstants.TAKE_BUTTON_ACTION_SUCCESS:
            return{
                ...state,
                recallEnabled: false,
                killTimeout: true
            };
        case eventConstants.ENABLE_RECALL:
            return{
                ...state,
                recallEnabled: true,
                killTimeout: false
            };
        case eventConstants.DROP_BUTTON_ACTION_SUCCESS:
            return{
                ...state,
                recallEnabled: true,
                killTimeout: true
            };

        case eventConstants.RECALL_BUTTON_ACTION_SUCCESS:
            return{
                ...state,
                iterations: state.iterations + 1,
                recallEnabled: false,
                killTimeout: false
            };

        case eventConstants.NEXT_ACTION_SUCCESS:
            return{
                ...state,
                iterations: 0,
                recallEnabled: true,
                killTimeout: false
            };


        default:
            return state;
    }
};




const operatorPopupState = {
    popupFormat: "",
    popupPayload: {},
    popupLifeEnded: false,

    transferActiveTab: 0,
    transferData:{},
    transferSelected: null,
    transferSelectedType: "",
    enableTransferButtons: false
};

export const operatorPopupStateDetect = (state = operatorPopupState, action) => {
    console.log(" [[[[[[[[[  REDUCER: operatorPopupStateDetect  ]]]]]]]]] ", action);

    switch (action.type) {

        case eventConstants.LOGOUT_SUCCESS:
            return {
                ...state,
                ...operatorPopupState
            };

        case eventConstants.RESUME_BREAK_ACTION_SUCCESS:
            return {
                ...state,
                popupLifeEnded: true
            };

        case eventConstants.START_BREAK_ACTION_SUCCESS:

            return {
                ...state,
                popupFormat: operatorPopupConstants.POPUP_ON_BREAK
            };
        case operatorPopupConstants.POPUP_ACTION_CANCEL:
            return {
                ...state,
                popupLifeEnded: true
            };

        case operatorPopupConstants.TRANSFER_ITEM_SELECTED:
            return {
                ...state,
                transferSelected: action.payload.transferSelected,
                transferSelectedType: action.payload.transferSelectedType,
                enableTransferButtons: true
            };


        case operatorPopupConstants.TRANSFER_TAB_SWITCH:
            return {
                ...state,
                transferActiveTab: action.payload.transferActiveTab,
                transferSelected: null,
                enableTransferButtons: false
            };

        case eventConstants.TRANSFER_BUTTON_ACTION_SUCCESS:
            return {
                ...state,
                popupLifeEnded: true
            };
        case eventConstants.TRANSFER_BUTTON_ACTION_SUCCESS_POPUP:
            return {
                ...state,
                popupLifeEnded: false,
                popupFormat: operatorPopupConstants.POPUP_TYPE_TRANSFER,
                popupPayload:{...action.payload},
                transferActiveTab: 0,
                transferData: action.payload.response,
                transferSelected: null,
                enableTransferButtons: false,
            };

        case eventConstants.NEXT_ACTION_SUCCESS:
        case eventConstants.TAKE_BUTTON_ACTION_SUCCESS:
        case eventConstants.POSTPONE_BUTTON_ACTION_SUCCESS:
        case eventConstants.DROP_BUTTON_ACTION_SUCCESS:
        case eventConstants.FINISH_BUTTON_ACTION_SUCCESS:
            return {
                ...state,
                popupLifeEnded: true
            };

        case operatorPopupConstants.SHOW_SETTINGS_POPUP:
            console.log("         case operatorPopupConstants.POPUP_TYPE_TRANSFER:");

            return {
                ...state,
                popupLifeEnded:false,
                popupFormat: action.type
            };

        case operatorPopupConstants.POPUP_TYPE_TRANSFER:
            console.log("         case operatorPopupConstants.POPUP_TYPE_TRANSFER:");

            return {
                ...state,
                popupLifeEnded:false,
                popupFormat: action.type,
                popupPayload:{...action.payload}
            };

        case operatorPopupConstants.POPUP_TYPE_CONFIRMATION:
            console.log("         case operatorPopupConstants.POPUP_TYPE_CONFIRMATION:");

            return {
                ...state,
                popupLifeEnded:false,
                popupFormat: action.type,
                popupPayload:{...action.payload}
            };
        default:
            return state;
    }
};

const operatorState = {

};

export const operatorStateDetect = (state = operatorState, action) => {
    console.log(" [[[[[[[[[  REDUCER: operatorStateDetect  ]]]]]]]]] ", action);

    switch (action.type) {

        default:
            return state;
    }
};


const operatorChooseTicketsState = {
    status: globalConstants.OPERATOR_WAITING,
    hideContent: true,

    acceptMiddleState: true,

    activeButton: "",
    postponed: [],
    dropped: [],
    manual: [],


    ticketInfo: [],
    ticketStatus: -1,
    statusInfo: {},
    ticketNumber: -1

};

export const operatorChooseTicketsDetect = (state = operatorChooseTicketsState, action) => {
    console.log(" [[[[[[[[[  REDUCER: operatorChooseTicketsDetect  ]]]]]]]]] ", action);

    switch (action.type) {
        case eventConstants.LOGOUT_SUCCESS:
            return {
                ...state,
                ...operatorChooseTicketsState
            };
        case eventConstants.POSTPONE_BUTTON_ACTION_SUCCESS:
            console.log("         case eventConstants.POSTPONE_BUTTON_ACTION_SUCCESS:");

            return {
                ...state,
                status: globalConstants.OPERATOR_WAITING,
                activeButton: buttonsConstants.NEXT_BUTTON,

                ticketStatus: -1,
                ticketNumber: -1,
                response: {},

                hideContent: true
            };
        case eventConstants.TRANSFER_BUTTON_ACTION_SUCCESS:
            console.log("         case eventConstants.FINISH_BUTTON_ACTION_SUCCESS:");

            return {
                ...state,
                status: globalConstants.OPERATOR_WAITING,
                activeButton: buttonsConstants.NEXT_BUTTON,

                ticketStatus: -1,
                ticketNumber: -1,
                response: {},

                hideContent: true
            };
        case eventConstants.FINISH_BUTTON_ACTION_SUCCESS:
            console.log("         case eventConstants.FINISH_BUTTON_ACTION_SUCCESS:");

            return {
                ...state,
                status: globalConstants.OPERATOR_WAITING,
                activeButton: buttonsConstants.NEXT_BUTTON,

                ticketStatus: -1,
                ticketNumber: -1,
                response: {},

                hideContent: true
            };

        case eventConstants.TAKE_BUTTON_ACTION_SUCCESS:
            console.log("         case eventConstants.FINISH_BUTTON_ACTION_SUCCESS:");

            return {
                ...state,
                status: globalConstants.OPERATOR_OPEN,
                activeButton: buttonsConstants.NEXT_BUTTON,

                ticketInfo: [...action.payload.response.info],
                ticketStatus: 4,
                ticketNumber: action.payload.response.ticket,
                response: {},

                hideContent: false
            };

        case eventConstants.DROP_BUTTON_ACTION_SUCCESS:
            console.log("         case eventConstants.NEXT_ACTION_OPERATOR:");

            return {
                ...state,
                status: globalConstants.OPERATOR_WAITING,
                activeButton: buttonsConstants.NEXT_BUTTON,

                ticketStatus: -1,
                ticketNumber: -1,
                response: {},

                hideContent: true
            };

        case eventConstants.NEXT_ACTION_SUCCESS:
            console.log("         case eventConstants.NEXT_ACTION_OPERATOR:");

            let theTicketStatus;
            if (action.payload.request.status === "ok") {
                theTicketStatus = 2;
            } else{
                theTicketStatus = action.payload.response.status;
            }

            return {
                ...state,
                status: globalConstants.OPERATOR_OPEN,
                activeButton: buttonsConstants.NEXT_BUTTON,
                ticketInfo: [...action.payload.response.info],
                ticketStatus: theTicketStatus,
                ticketNumber: action.payload.response.ticket,
                response: {},
                hideContent: false
            };


        case eventConstants.NEXT_ACTION_OPERATOR:
            console.log("         case eventConstants.NEXT_ACTION_OPERATOR:");

           console.log(" OPEN POPUP ");

            return state;
           break;


        case eventConstants.UPDATE_OPERATOR_CONTENT:
            console.log("         case eventConstants.UPDATE_OPERATOR_CONTENT:");
            if(state.acceptMiddleState === true){
                if(action.inwork){
                    return {
                        ...state,
                        status: globalConstants.OPERATOR_OPEN,
                        activeButton: buttonsConstants.NEXT_BUTTON,
                        perms: {...action.perms},
                        postponed: [...action.postponed],
                        dropped: [...action.dropped],
                        manual: [...action.manual],
                        acceptMiddleState: false,
                        ticketInfo: [...action.inwork.info],
                        ticketStatus: action.inwork.status,
                        ticketNumber: action.inwork.ticket,
                        hideContent: false
                    };
                }

            }
            return {
                ...state,
                perms: {...action.perms},
                postponed: [...action.postponed],
                dropped: [...action.dropped],
                manual: [...action.manual]
            };

        case buttonsConstants.BUTTON_EVENT:
            console.log("         case buttonsConstants.BUTTON_EVENT:");
            return {
                ...state,
                activeButton: action.butType,
                hideContent: false
            };

        default:
            return state;
    }
};



const operatorTimeState = {
    time: "00:00"
};

export const operatorTimeDetect = (state = operatorTimeState, action) => {
 //   console.log(" [[[[[[[[[  REDUCER: operatorTimeDetect  ]]]]]]]]] ", action);

    switch (action.type) {
        case eventConstants.LOGOUT_SUCCESS:
            return {
                ...state,
                ...operatorTimeState
            };
        case eventConstants.GET_TIME:
             return {
                ...state,
                time: getTime()
            };

        default:
            return state;
    }
};



const detectPrintPermission = (pArray) =>{
    let enablePrint = false;
    for (let idx = 0; idx < pArray.length; idx++) {
        if (pArray[idx].selected === true) {
            enablePrint = true;
            break;
        }
    }

    return enablePrint;
};

const ticketPrintState = {
    items: [],
    printEnabled: false,
    total: -1,
    screen: eventConstants.TICKET_PRINT_SCREEN_SHOW,
    lang: globalConstants.LANG_EN,
    showPrinting: false,
    printingSuccess: false,
    showPrintingTexts: {

    },

    popup:{
        opened: false,
        popupType: "",
        popupId: -1,
        enableLogoutButtons: false,
        logoutInputResult: "",
        permitLogoutCall: false,
        item: {
            response: {}
        }
    }
};

export const ticketPrintDetect = (state = ticketPrintState, action) => {
    console.log(" [[[[[[[[[  REDUCER: ticketPrintDetect  ]]]]]]]]] ", action);

    switch (action.type) {
        case eventConstants.PIN_NEW_CHAR_INPUT:
            let newResult = state.popup.logoutInputResult;

            if (action.payload.value !== "<") {
                newResult += action.payload.value;
            } else{
                if(newResult.length >0 ){
                    newResult = newResult.slice(0, -1);
                }
            }

            return {
                ...state,
                popup: {
                    ...state.popup,
                    permitLogoutCall: true,
                    logoutInputResult: newResult
                }
            };
        case eventConstants.LOGOUT_SUCCESS:
            return {
                ...state,
                ...ticketPrintState
            };

        case eventConstants.PIN_SUCCESS:
            return {
                ...state,
                popup:{
                    ...state.popup,
                    permitLogoutCall: false,
                    enableLogoutButtons: true
                }
            };

        case operatorPopupConstants.POPUP_PIN:
            return {
                ...state,
                popup:{
                    ...state.popup,
                    opened: true,
                    popupType: action.type,
                    logoutInputResult: ""
                }
            };

        case eventConstants.POPUP_OPEN_SELECT_TICKET_DONE:
            let items = state.items;
            let item = state.popup.item;

            for (let idx = 0; idx < items.length; idx++) {
                if (items[idx].id === item.id) {
                    let atLeastOneTrue = false;
                    for (var j = 0; j < items[idx].sub.length; j++) {
                        if (items[idx].sub[j].id === item.sub[j].id) {
                            items[idx].sub[j].selected = item.sub[j].selected;
                            if(item.sub[j].selected === true){
                                atLeastOneTrue = true;
                            }
                        }
                    }
                    items[idx].selected = atLeastOneTrue;
                    break;
                }
            }


            return {
                ...state,
                ...items,
                printEnabled: detectPrintPermission(items),
                popup: {
                    ...state.popup,
                    opened: false
                }
            };

        case eventConstants.POPUP_OPEN_SELECT_TICKET_BACK:
            return {
                ...state,
                popup:{
                    opened: false
                }
            };

        case eventConstants.POPUP_OPEN_SELECT_TICKET_ITEM:
            let modifItem = cloneDeep(state.popup.item);

            let newItem = modifItem.sub[action.itemKey];
            newItem.selected = newItem.selected !== true;

            return {
                ...state,
                popup:{
                    ...state.popup,
                    item: {...modifItem}
                }

            };



        case eventConstants.POPUP_OPEN_SELECT_TICKET:
            return {
                ...state,
                popup:{
                    ...state.popup,
                    opened: true,
                    popupType: action.type,
                    item: {...action.item},
                    popupId: action.popupId
                }
            };

        case eventConstants.DONE_SHOWING_PRINT_SCREEN:
            return {
                ...state,
                showPrinting: false,
                printingSuccess: false,
            };

        case eventConstants.PRINTING_TICKET_UPDATE_INFO:
              return {
                ...state,
                printingSuccess: true,
                showPrintingTexts: {...action.texts}
            };

        case eventConstants.TICKET_PRINT_STATUS_RESET:
            var resetArray = cloneDeep(state.items);
            for (let item of resetArray) {
                if (item.selected === true) {
                    item.selected = false;
                    if(item.sub){
                        item.sub.forEach((subItem, subKey) => {
                            if(subItem.selected === true){
                                subItem.selected = false;
                            }
                        });
                    }
                }
            }

            return {
                ...state,
                items: [...resetArray],
                printEnabled: false,
                showPrinting: true,
                printingSuccess: false,
                showPrintingTexts: {...action.texts}
            };


        case eventConstants.SELECT_TICKET_BUTTON:
            let copyAr = [...state.items];
            copyAr[action.itemKey].selected = copyAr[action.itemKey].selected !== true;

            return {
                ...state,
                items: [...copyAr],
                printEnabled: detectPrintPermission(copyAr)
            };




        case eventConstants.UPDATE_TICKET_CONTENT:
            let popShow = false;
            let idx = 0;
            let itemPopup = {};

            if (state.popup.opened === true) {
                for (idx = 0; idx < action.items.length; idx++) {
                    if (action.items[idx].id === state.popup.popupId) {
                        if (action.items[idx].status === 1) {
                            popShow = true;
                            itemPopup = cloneDeep(action.items[idx]);
                        } else {
                            popShow = false;
                        }
                        break;
                    }
                }
            }

            return {
                ...state,
                total: action.total,
                items: action.items,
                printEnabled: detectPrintPermission(action.items),
                popup:{
                    ...state.popup,
                    opened: popShow,
                    item: itemPopup
                }
            };

        default:
            return {...state};
    }
};










const getTime = ()=>{
    let myDate = new Date();
    let hours = myDate.getHours();
    let minutes = myDate.getMinutes();
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return hours + ":" + minutes;
};

const animNewsState = {
    items:[],
    currentTextIdx: -1,
    total: 0,
    config: {},
    wrapperWidth: 0,
    wrapperHeight: 0,
    time: "00:00"
};

export const animNewsDetect = (state = animNewsState, action) => {
    switch (action.type) {
        case eventConstants.LOGOUT_SUCCESS:
            return {
                ...state,
                ...animNewsState
            };
        case eventConstants.GET_TIME:
            return {
                ...state,
                time: getTime()
            };

        case eventConstants.UPDATE_NEWS_CONTENT:
            return {
                ...state,
                items: action.items,
                currentTextIdx: -1,
                total: action.total,
                config: action.config,
                wrapperWidth: action.wrapperWidth
            };

        case eventConstants.START_NEWS_TICKER_ANIMS:
            return {
                ...state,
                currentTextIdx: 0
            };

        case eventConstants.NEWS_TICKER_ANIM_COMPLETE:
            return {
                ...state,
                currentTextIdx: state.currentTextIdx === state.total ? 0 : state.currentTextIdx + 1
            };
        default:
            return state;
    }
};








































const initialLoginState = {
    user: getLocalUserData(globalConstants.LOCAL_STORAGE_OBJ_USER),
    pass: getLocalUserData(globalConstants.LOCAL_STORAGE_OBJ_PASS),
    ping: getLocalUserData(globalConstants.LOCAL_STORAGE_OBJ_PING),

    loggedIn: false,
    formValid: false,

    screen: "",
    serverData: ""
};

export const screenDetect = (state = initialLoginState, action) => {
   console.log(" [[[[[[[[[  REDUCER: screenDetect  ]]]]]]]]] ", action);

    switch (action.type) {

        case eventConstants.LAUNCH_SCREEN_TICKET:
            console.log("                               case eventConstants.LAUNCH_SCREEN_TICKET:");
            return {
                ...state,
                screen: eventConstants.LAUNCH_SCREEN_TICKET
            };
       /* case eventConstants.LAUNCH_SETUP:
        case eventConstants.FAIL_PING:
                console.log("                             case eventConstants.LAUNCH_SETUP:");
            return {
                ...state,
                screen: eventConstants.LAUNCH_SETUP
            };*/

        case eventConstants.LAUNCH_LOGIN:
            return {
                ...state,
                screen: eventConstants.LAUNCH_LOGIN
            };
        case eventConstants.SUCCESS:
            switch (action.payload.subType) {
                case eventConstants.SUCCESS_PING:
                    return {
                        ...state,
                        serverData: action.payload.request
                    };

                default:
                    return {
                        ...state
                    };

            }
        case eventConstants.SAVE_TO_LOCAL_STORAGE:
            return{
                ...state
            };
                
        default:
            return state;
    }
};




const initialSetupState = {
    title: "Setup",
    fieldTitle: "Server Address",
    buttonTitle: "Test",
    serverAddress: getLocalUserData("ping"),
    serverData: "",
    success: false,
    stored: false,
    submitDisabled: false
};

export const setupScreenBehavior = (state = initialSetupState, action) => {
  //  console.log(" [[[[[[[[[  REDUCER: setupScreenBehavior  ]]]]]]]]] ", action);

    switch (action.type) {


        case eventConstants.LAUNCH_SCREEN_TICKET:
            console.log("                      case eventConstants.LAUNCH_SCREEN_TICKET:");
            return {
                ...state,
                screen: eventConstants.LAUNCH_SCREEN_TICKET
            };
        case eventConstants.LAUNCH_SETUP:
            console.log("                      case eventConstants.LAUNCH_SETUP:");
            return {
                ...state,
                screen: eventConstants.LAUNCH_SETUP
            };

        case eventConstants.STORE:
            console.log("                       case eventConstants.STORE:");
            return {
                ...state,
                serverAddress: action.payload.val
            };

        case globalConstants.API:
            console.log("                       case globalConstants.API:  ");
            return{
                ...state,
                submitDisabled: "true"
            };

        case eventConstants.SUCCESS:
            switch (action.payload.subType) {
                case eventConstants.SUCCESS_PING:

                    return {
                        ...state,
                        serverData: action.payload.request,
                        submitDisabled: true
                    };
                default:
                    return {
                        ...state
                    };

            }

        case eventConstants.SAVE_TO_LOCAL_STORAGE:
            return {
                ...state
            };

        default:
            return state;
    }
};

export const loginValidate = (state = initialLoginState, action) => {
  //  console.log(" [[[[[[[[[  REDUCER: loginValidate  ]]]]]]]]] ", action);

    switch (action.type) {

        case alertConstants.ERROR:
            return Object.assign({}, state, {
                alert:{
                    type: action.type,
                    message: action.message
                }
            });

        case globalConstants.VALIDATE_SEND_FORM:

            return Object.assign({}, state, {
                formValid: action.payload.formValid
            });

        case eventConstants.FORM_CHANGE:
            return Object.assign({}, state, {
                user: action.payload.field === "user" ? action.payload.val : state.user,
                pass: action.payload.field === "pass" ? action.payload.val : state.pass
            });

        default:
            return state;
    }

};
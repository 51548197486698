import React, {Component, Fragment} from 'react';
import { connect } from "react-redux";
import {eventConstants, globalConstants} from "../../constants";
import {ReactComponent as LogoutIcon} from "../../assets/img/logout.svg";
import popupLogoutAction from "../../actions/popupLogout.action";
import PinInputItem from "./PinInputItem";
import {Linear, TweenMax} from "gsap";
import {ReactComponent as BackGraphic} from "../../assets/img/back.svg";
import {isEmpty, translateText, translateTextPopup} from "../../constants/utils";
import {ReactComponent as CheckGraphic} from "../../assets/img/check.svg";
import ticketAction from "../../actions/ticket.action";
import EventBus from "../../constants/EventBus";
import NewsFeedItem from "../newsfeed/NewsFeedItem";
import TransitionGroup from "../../transition/TransitionGroup";
import CSSTransition from "../../transition/CSSTransition";
import Transition from "../../transition/Transition";

class PopupLogout extends Component {

    constructor(props){
        super(props);

        this.myTween = null;
        this.theComponent = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.theComponent.current) {
            let {popupNameEntryState} = this.props;

            if (popupNameEntryState.shown === true) {
                TweenMax.to(this.theComponent.current, 0.2,
                    {autoAlpha: 1, ease: Linear.easeNone});
            } else {
               TweenMax.to(this.theComponent.current, 0.2,
                    {autoAlpha: 0, ease: Linear.easeNone});
            }
        }
    }

    backButtonPressed(e){
        if(e) e.preventDefault();
        let {ticketAction} = this.props;
        ticketAction({
            type: eventConstants.POPUP_OPEN_PRINT_TICKET_BACK
        });
    }

    buttonPressed(e){
        if(e) e.preventDefault();
        EventBus.getInstance().fireEvent("printTriggeredFromPopup", { })
    }

    setValue (field, event) {
        let {ticketAction} = this.props;
        ticketAction({
            type: eventConstants.POPUP_OPEN_PRINT_TICKET_ENTRY_TEXT,
            inputResult: event.target.value
        });
    }

    renderTickets(){
        let {loginState, ticketState, ticketAction, popupNameEntryState} = this.props;

        let items = popupNameEntryState.jobs;

        return(
                <ul>
                    {items.map((item, i) =>
                        <li key={i}>{(i+1) + " " + item.job}</li>
                    )}
                </ul>
        )
    }

    renderTitle(){
        let {popupNameEntryState, loginState} = this.props;
        if(!isEmpty(popupNameEntryState.inputResult)){
            return(
                <div className="title">
                    <span className="title-name">{popupNameEntryState.inputResult}</span>
                    , here's your ticket:
                </div>
            )
        } else{
            return null
        }

    }

    renderDangerously() {
        return (
            <div dangerouslySetInnerHTML={this.createMarkup()}/>
        )
    }

    createMarkup() {
        let { ticketState} = this.props;
        return {
            __html: ticketState.ticketPrintResponse.popup
        };
    }

    render() {

        let {popupNameEntryState, loginState, ticketState} = this.props;

        if(popupNameEntryState.launched === false){
            if(this.theComponent.current){
                TweenMax.to(this.theComponent.current, 0.2,
                    {autoAlpha: 0, ease: Linear.easeNone});
            }
            return null;
        }

        if(this.theComponent.current){
            TweenMax.to(this.theComponent.current, 0.2,
                {autoAlpha: 1, ease: Linear.easeNone});
        }

        let printButtonClass = popupNameEntryState.enableButton === false ? "disabled" :"";
        printButtonClass += " popup_input_name";

        let extraClass = popupNameEntryState.showPrinting === true ? " printing" : "";
        extraClass += "alternate-title";

        let thanks = "";
        if(!isEmpty(ticketState.ticketPrintResponse.ticket)){
            thanks = translateTextPopup(loginState, "thanks", "title");
            thanks = thanks.replace("{TICKET}",ticketState.ticketPrintResponse.ticket.no )
        }

        return (
            <div className="smartq-popup" ref={this.theComponent}>
                <div className="popup-overlay"></div>
                <div className="popup-data">
                    <div className="popup-container">
                        <div className="popup-content">
                            <div className="container">
                                <div className="inside-content">
                                    <div className="popup-operator-content">

                                        <div className="popup-title">

                                                <div className="title">
                                                    <CSSTransition
                                                        in={!popupNameEntryState.showPrinting}
                                                        timeout={500}
                                                        classNames="title-text"
                                                        appear
                                                    >
                                                        <span className="title-text">{translateTextPopup(loginState,  "name", "title")}</span>
                                                    </CSSTransition>

                                                    <CSSTransition
                                                        in={popupNameEntryState.showPrinting}
                                                        timeout={500}
                                                        classNames="title-text-alternate"
                                                        appear
                                                    >
                                                        <span
                                                            className="title-text-alternate">{thanks}</span>
                                                    </CSSTransition>

                                                </div>

                                        </div>

                                        <CSSTransition
                                            in={popupNameEntryState.showPrinting}
                                            timeout={500}
                                            classNames="ticket-data"
                                            appear
                                            unmountOnExit
                                        >

                                                <div className="ticket-data">
                                                    {this.renderDangerously()}
                                                   {/* {this.renderTitle()}
                                                    {this.renderTickets()}*/}
                                                </div>

                                        </CSSTransition>

                                        <CSSTransition
                                            in={!popupNameEntryState.showPrinting}
                                            timeout={500}
                                            classNames="name-input-holder"
                                            unmountOnExit
                                        >
                                            <div className="name-input-holder">
                                                <input className="name-input"
                                                       type="text"
                                                       placeholder={popupNameEntryState.inputResult}
                                                       value={popupNameEntryState.inputResult}
                                                       name="name"
                                                       required
                                                       onChange={this.setValue.bind(this, 'name')}
                                                />
                                            </div>
                                        </CSSTransition>



                                        <CSSTransition
                                            in={!popupNameEntryState.showPrinting}
                                            timeout={500}
                                            classNames="action-buttons"
                                            unmountOnExit
                                        >
                                            <div className="action-buttons">
                                                <button className="back-action" type="button"
                                                        onClick={(e) => this.backButtonPressed(e)}>
                                                    <span
                                                        className="texts">{translateTextPopup(loginState, "name", "btn", "cancel")}</span>
                                                </button>

                                                <button className={printButtonClass} type="button"
                                                        disabled={popupNameEntryState.enableButton === false ? "disabled" : ""}
                                                        onClick={(e) => this.buttonPressed(e)}>
                                                    <span className="texts">{translateTextPopup(loginState,  "name","btn", "ok")}</span>
                                                </button>
                                            </div>
                                        </CSSTransition>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    popupNameEntryState: state.popupNameEntryState,
    ticketState: state.ticketState,
    loginState: state.loginState
});

const mapDispatchToProps =  {
    ticketAction
};

export default connect(mapStateToProps, mapDispatchToProps)(PopupLogout);
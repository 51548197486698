import React, { Component } from 'react';
import { ReactComponent as BottomLogo} from '../../../assets/img/developer.svg';

class FormBottom extends Component {
    render() {
          return (
            <div className="bottom-content">
                <div className="app-developer">
                    <div className="developer-content">
                        <span className="text">powered by</span>
                        <span className="developer-logo">
                            <BottomLogo className="developer-svg" id="developer-svg"/>
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

export default FormBottom;


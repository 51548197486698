import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import queueAction from "../../actions/queueAction";
import {cloneDeep} from "lodash";
import QueueListItem from "./QueuListItem";
import TransitionGroup from "../../transition/TransitionGroup";

class QueueList extends Component {
    constructor(props) {
        super(props);

        this.cTickets = [];
        this.qListItems = [];
        this.cnt = 0;
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        let newTickets = this.processNewTickets();

        if(this.cTickets.length === 0){
            this.cTickets = cloneDeep(this.processNewTickets());
            return true;
        }

        for(let idx = 0; idx < this.cTickets.length; idx++){
            for(let j = 0; j < newTickets.length; j++){
                if(this.cTickets[idx].id === newTickets[j].id){
                    if(idx !== j){
                        this.cTickets[idx].config = cloneDeep(newTickets[j].config);
                        break;
                    }
                }
            }
        }
        return true;
    }

    processNewTickets() {
        let {loginState, queueState} = this.props;

        let tickets = cloneDeep(this.props.tickets.tickets);
        let j;

        let listConfig = queueState.skins[loginState.devSkin];

        let actualConfigList = listConfig.lists[this.props.idx];
        let emptyIter = -1;
        if(tickets.length !== actualConfigList.tickets.length){
            let startIdx = tickets.length;
            for(startIdx; startIdx < actualConfigList.tickets.length; startIdx++){
                tickets.push({status: "empty", id:emptyIter, show: true, no: null});
                emptyIter--;
            }
        }

        for (j = 0; j < tickets.length; j++) {
            tickets[j].config = cloneDeep(actualConfigList.tickets[j]);
        }

        return tickets;
    }

    renderTitle() {
        let {loginState, queueState} = this.props;
        let listConfig = queueState.skins[loginState.devSkin];
        let textsLang = loginState.language[loginState.currentLang][loginState.config.type].texts;

        if (listConfig.lists[this.props.idx].title !== null) {
            return (
                <div className={"tc-title tc-title-bar-" + parseInt(this.props.idx + 1)}>
                    <div className="ticket-head">{textsLang.ticket}</div>
                    <div className="counter-head">{textsLang.counter}</div>
                </div>
            )
        }
        return null;
    }

    generateNewItem(value, idx, pKey, pShow, pShouldAppear) {
        let {loginState, queueState, queueAction} = this.props;

        return <QueueListItem
            key={pKey} cnt={pKey} idx={idx}
            value={value}
            loginState={loginState} queueState={queueState} queueAction={queueAction}
            parentIdx={this.props.idx} show={pShow} shouldAppear={pShouldAppear}
        />;
    }

    render() {
        let {loginState, queueState, queueAction} = this.props;

        this.cTickets = [];
        this.cTickets = cloneDeep(this.processNewTickets());
        let i, j, k, found, qLength, okItems = [];

        for(i = 0; i < this.cTickets.length; i++){
            for(j = 0; j < this.qListItems.length; j++){
                if(this.qListItems[j].props.value.id === this.cTickets[i].id){
                    this.qListItems[j] = this.generateNewItem(this.cTickets[i], i, j, true, false);
                    okItems.push(i);
                    break;
                }
            }
        }

        qLength = this.qListItems.length;
        if (okItems.length !== this.cTickets.length) {
            for (i = 0; i < this.cTickets.length; i++) {
                found = false;
                for (j = 0; j < okItems.length; j++) {
                    if (okItems[j] === i) {
                        found = true;
                        break;
                    }
                }
                if (found === false) {
                    for (k = 0; k < qLength; k++) {
                        if (this.qListItems[k].props.show === false) {
                            found = true;
                            this.qListItems[k] = this.generateNewItem(this.cTickets[i], i, k, true, true);
                            break;
                        }
                    }
                    if (found === false) {
                        this.qListItems.push(this.generateNewItem(this.cTickets[i], i, this.qListItems.length, true, true));
                    }
                }
            }
        }


        for (j = 0; j < this.qListItems.length; j++) {
            found = false;
            for (i = 0; i < this.cTickets.length; i++) {
                if (this.qListItems[j].props.value.id === this.cTickets[i].id) {
                    found = true;
                    break;
                }
            }

            if (found === false) {
                this.qListItems[j] = this.generateNewItem(this.qListItems[j].props.value, 0, j, false, false);
            }
        }

       /*if(this.props.idx === 0){
            for(i = 0; i < this.qListItems.length; i++){
                console.log(this.qListItems[i].props.show + " " + this.qListItems[i].props.shouldAppear + " "
                + this.qListItems[i].props.value.no + " ",
                    this.qListItems[i].props.value.config);
                console.log(this.qListItems[i].props.value);
            }
        }*/

        return (
            <Fragment>
                {this.renderTitle()}
                <TransitionGroup component={null}>
                    {this.qListItems}
                </TransitionGroup>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    loginState: state.loginState,
    queueState: state.queueState
});

const mapDispatchToProps = {
    queueAction
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(QueueList);



/*

if(this.cnt === 10){
    this.cnt = 10;
    for(i = 10; i >5; i--){
        this.qListItems.push(<QueueListItem
            key={this.cnt} cnt={this.cnt} idx={i}
            value={this.cTickets[i]}
            loginState={loginState} queueState={queueState}
            parentIdx={this.props.idx} show={false}
        />);
        this.cnt--;
    }
}
if(this.cnt===5){
this.cnt = 0;
}

for(i = 0; i < this.cTickets.length; i++){
    this.qListItems.push(<QueueListItem
        key={this.cnt} cnt={this.cnt} idx={i}
        value={this.cTickets[i]}
        loginState={loginState} queueState={queueState}
        parentIdx={this.props.idx} show={true}
    />);
    this.cnt++;
}*/
/*
        for(let j=0; j<this.qListItems.length; j++){
            console.log(this.qListItems[j].props.show);
        }*/
/*let qListItems = this.cTickets.map((item, i) =>

    <QueueListItem key={i} value={item} idx={i} parentIdx={this.props.idx}
        ref={(ref) => this.itemRefs[i] = ref}
    />
);*/
/* console.log("---====render q list ");
 console.log(this.qListItems);
*/

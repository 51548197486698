import React, { Component } from 'react';
import { connect } from "react-redux";
import {eventConstants} from "../../constants";
import { ReactComponent as CheckMark} from '../../assets/img/check.svg';
import popupTicketAction from "../../actions/popupTicket.action";
import EventBus from "../../constants/EventBus";

class PopupTicketItem extends Component {

    constructor(props){
        super(props);

        this.myButton = React.createRef();
    }

    buttonPressed(e){
        if(e) e.preventDefault();
        let { loginState, popupTicketAction, popupTicketState} = this.props;

        if(loginState.config.print_button === "instant"){
            EventBus.getInstance().fireEvent("instantPrint", {
                itemToPrint: popupTicketState.item.sub[this.props.itemKey],
                popupSource: true
            })
        } else {
            popupTicketAction({
                type: eventConstants.POPUP_OPEN_SELECT_TICKET_ITEM,
                itemKey: this.props.itemKey
            });
        }
    }

    render() {
        let {popupTicketState, loginState} = this.props;

        let itemData = popupTicketState.item.sub[this.props.itemKey];
        let buttonClass = (itemData.status === 0) ? "disabledButton" : "";
        let selected = "";

        if (itemData.status === 1) {
            selected = (itemData.selected === true) ? "active" : "";
        }
        buttonClass += " " + selected;

        let disabledButtonState = (itemData.status === 0);

        return (
            <button
                ref={this.myButton}
                className={buttonClass}
                type="button" key={this.props.itemKey}
                disabled={disabledButtonState}
                onClick={(e) => this.buttonPressed(e)}>
                   <span className="icon">
                         <CheckMark className="developer-svg" id="developer-svg"/>
                   </span>
                <span className="texts">{itemData.name[loginState.currentLang]}</span>
            </button>
        );
    }
}

const mapStateToProps = (state) => ({
    popupTicketState: state.popupTicketState,
    loginState: state.loginState
});

const mapDispatchToProps =  {
    popupTicketAction
};

export default connect(mapStateToProps, mapDispatchToProps)(PopupTicketItem);
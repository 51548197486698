import '../../assets/css/operator/operator.css';
import React, {Component, Fragment} from 'react';
import { connect} from "react-redux";
import {eventConstants} from "../../constants";
import ChooseTickets from "./ChooseTickets";
import operatorAction from "../../actions/operator.action";

class Operator extends Component {

    constructor(props){
        super(props);
    }

    componentDidMount() {
        let {loginState, operatorAction} = this.props;

        operatorAction({
            type: eventConstants.TIMER_START,
            timerName: "tickets_list",
            timerInterval: loginState.config.tickets_refresh * 1000,
            secondCall:{
                callType: eventConstants.TIMER_API,
                success: eventConstants.UPDATE_OPERATOR_CONTENT,
                fail: eventConstants.API_LIGHT_FAIL,
                url: loginState.config.actions.tickets_list + loginState.loginAuth
            }
        });
    }

    componentWillUnmount() {
        let {operatorAction} = this.props;

        operatorAction({
            type: eventConstants.TIMER_STOP,
            timerName: "tickets_list",
        });
    }

    render() {
        return (
            <Fragment>
                <div className="smartq-app-operator">
                    <div className="smartq-app-container">
                        <div className="operator-lists">
                            <ChooseTickets />
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    operatorState: state.operatorState,
    loginState: state.loginState
});

const mapDispatchToProps =  {
    operatorAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Operator);
import {eventConstants, globalConstants} from "../constants";

const popupTicketAction = (payload) => {
    switch (payload.type) {

        case eventConstants.POPUP_OPEN_SELECT_TICKET_DONE:
        case eventConstants.POPUP_OPEN_SELECT_TICKET_ITEM:
        case eventConstants.POPUP_OPEN_SELECT_TICKET_BACK:
        case eventConstants.POPUP_OPEN_SELECT_TICKET:
            componentActionName(payload);
            return {
                type: payload.type,
                payload
            };

        default:
            return {
                type: payload.type,
                payload
            }
    }
};

const componentActionName = (payload) => {
    if (globalConstants.DEV_ENABLE_LOG === true) {
        console.log("--- ACTION: popupTicketAction     ", payload.type);
        console.log("payload:  ", payload);
    }
};

export default popupTicketAction;
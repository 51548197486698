import {eventConstants, globalConstants} from "../constants";

const popupLogoutAction = (payload) => {
    switch (payload.type) {
        case eventConstants.POPUP_OPEN_SELECT_TICKET_BACK:
        case eventConstants.PIN_NEW_CHAR_INPUT:
        case eventConstants.SHOW_POPUP_LOGOUT:
            componentActionName(payload);
            return {
                type: payload.type,
                payload
            };

        default:
            componentActionName(payload);
            return {
                type: payload.type,
                payload
            }
    }
};

const componentActionName = (payload) => {
    if (globalConstants.DEV_ENABLE_LOG === true) {
        console.log("--- ACTION: popupLogoutAction     ", payload.type);
        console.log("payload:  ", payload);
    }
};

export default popupLogoutAction;
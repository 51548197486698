import React, {Component} from 'react';
import { ReactComponent as Arrow} from '../../assets/img/arrow.svg';
import { ReactComponent as Circle} from '../../assets/img/circle.svg';
import {isEmpty, translateText} from "../../constants/utils";
import {Linear, Power4, TweenMax} from "gsap";
import Transition from "../../transition/Transition"
import EventBus from "../../constants/EventBus";
import {Howl, Howler} from "howler";
import {eventConstants} from "../../constants";


class QueueListItem extends Component {
    constructor(props) {
        super(props);

        this.myComponent = React.createRef();
        this.cValue = {};
        this.cIdx = -1;
        this.cParentIdx = -1;
        this.soundIter = -1;
        this.playing = false;
        this.conditionsMet = 0;
        this.myTimeout = null;
        this.myTween = null;

        this.callRecallMode = false;

        Howler.volume(1.0);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        let {loginState, queueState, queueAction} = this.props;

        let itemValue = nextProps.value;

        this.callRecallMode = false
        if(itemValue === "call" || itemValue === "recall"){
            this.callRecallMode = true;
        }
        if(nextProps.shouldAppear === true){
            if(this.callRecallMode === false){
                this.myComponent.current.style.left = itemValue.config.x + "px";
                this.myComponent.current.style.top = itemValue.config.y + "px";
            }

            this.myTween = TweenMax.to(this.myComponent.current, 0.5, {
                autoAlpha: 1,
                ease: Power4.easeOut,
                delay: 1
            });

        } else {
            if(nextProps.show === false){
                this.myTween = TweenMax.to(this.myComponent.current, 0.5, {
                    autoAlpha: 0,
                    ease: Power4.easeOut
                });
            } else {
                if(this.callRecallMode === false){
                    this.myTween = TweenMax.to(this.myComponent.current, 0.5, {
                        left: itemValue.config.x + "px",
                        top: itemValue.config.y + "px",
                        ease: Power4.easeOut,
                        autoAlpha: 1,
                        delay: 0.5
                    });
                }else{
                    this.myTween = TweenMax.to(this.myComponent.current, 0.5, {
                        ease: Power4.easeOut,
                        autoAlpha: 1,
                        delay: 0.5
                    });
                }

            }
        }

        return true;
    }

    componentDidMount(prevProps, prevState, snapshot) {
        if (this.myComponent.current) {
            let itemValue = this.props.value;

            if(this.callRecallMode === false){
                this.myTween = TweenMax.to(this.myComponent.current, 0.5, {
                    left: itemValue.config.x + "px",
                    top: itemValue.config.y + "px",
                    ease: Power4.easeOut
                });
            }

        }
    }

    playNextSound(){
        let {loginState, queueState, queueAction} = this.props;
        let listConfig = queueState.skins[loginState.devSkin];
        let recallSettings = listConfig.recall;
        let currentSound = "";

        if (recallSettings.audio === 1) {
            if(isEmpty(queueState.currentAction)){
                this.finishedSounds();
                return;
            }
            if (!isEmpty(queueState.currentAction.sound)) {
                if (this.playing === false) {
                    this.soundIter++;
                    if (this.soundIter < queueState.currentAction.sound.length) {
                        this.playing = true;
                        currentSound = queueState.currentAction.sound[this.soundIter];
                        console.log("PLAY:  ", currentSound);

                        let mySound = new Howl({
                            src: currentSound,
                            onplayererror: (()=>{
                                this.playing = false;
                                this.playNextSound();
                            }),
                            onloaderror:(()=>{
                                this.playing = false;
                                this.playNextSound();
                            }),
                            onend: (()=>{
                                mySound.unload();
                                this.playing = false;
                                this.playNextSound();
                            })
                        });

                        mySound.play();
                    } else{
                        this.finishedSounds();
                    }
                }
            } else{
                this.finishedSounds();
            }

        } else{
            this.finishedSounds();
        }
    }

    finishedSounds(){
        this.conditionsMet++;
        this.executeNextAction();
    }

    executeNextAction(){
        if(this.conditionsMet === 2){
            let {loginState, queueState, queueAction} = this.props;
            let listConfig = queueState.skins[loginState.devSkin];
            let itemConfig = listConfig.lists[this.props.parentIdx].tickets[this.props.idx];
            let listConfigStatus = listConfig[this.props.value.status];

            if(this.props.value.status === "call" || this.props.value.status === "recall") {
                if (listConfigStatus.style === "outside") {

                    EventBus.getInstance().fireEvent("REMOVE_CLASS_FROM_MAIN_SECOND_STAGE", {
                        className: listConfigStatus["outside"].cssClass.app
                    });

                }
            }

            queueAction({
                type: eventConstants.EXECUTE_NEXT_QUEUE_ACTION
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {loginState, queueState, queueAction} = this.props;
        let listConfig = queueState.skins[loginState.devSkin];
        let recallSettings = listConfig.recall;
        let itemValue = this.props.value;
        let currentAction = queueState.currentAction;

        if(!isEmpty(currentAction)){
            if(currentAction.id === itemValue.id){
                this.conditionsMet = 0;

                if(currentAction.status === "recall"){
                    let listConfig = queueState.skins[loginState.devSkin];
                    let listConfigStatus = listConfig[this.props.value.status];

                    if(listConfigStatus["outside"].cssClass.app){
                        EventBus.getInstance().fireEvent("ADD_CLASS_TO_MAIN_SECOND_STAGE", {
                            className: listConfigStatus["outside"].cssClass.app
                        });
                    }


                    if(!isEmpty(currentAction.sound)){
                        this.playing = false;
                        this.soundIter = -1;
                        this.playNextSound();
                    } else{
                        this.conditionsMet++;
                    }

                    this.myTimeout = setTimeout(()=>{
                        this.finishedSounds();
                    }, recallSettings.min_duration);
                }
            }
        }
    }

    render() {
        let {loginState, queueState} = this.props;

        this.cValue = this.props.value;
        this.cIdx = this.props.idx;
        this.cParentIdx = this.props.parentIdx;

        let theParentIdx = this.props.parentIdx

        let listConfig = queueState.skins[loginState.devSkin];
        let itemConfig = listConfig.lists[this.props.parentIdx].tickets[this.props.idx];
        let listConfigStatus = listConfig[this.props.value.status];
        let itemClass = "tc-element ticket-bar-" + theParentIdx + " " + itemConfig.cssClass;


        if(!isEmpty(listConfig.lists[this.props.parentIdx].cssClass.ticket[this.props.value.status])){
            itemClass += " " + listConfig.lists[this.props.parentIdx].cssClass.ticket[this.props.value.status];
        }

        this.callRecallMode = false;
        if(this.props.value.status === "call" || this.props.value.status === "recall"){
            if (listConfigStatus.style !== "outside") {
                itemClass += " " + listConfigStatus["list"].cssClass.ticket;
            }
        }



        let counterNumber = "";
        if(!isEmpty(this.props.value.device)){
            counterNumber = this.props.value.device;
        }

        let myClassArr = Array.from(new Set(itemClass.split(" ")));

        let textsLang = loginState.language[loginState.currentLang][loginState.config.type].texts;

        return (

                <Transition timeout={500} >

                    <div className={myClassArr.join(" ")} ref={this.myComponent}>

                        <div className="first-side">
                            <div className="ticket-title-number">
                                <div className="ticket-title">{textsLang.ticket}</div>
                                <div className="ticket-number">{this.props.value.no}</div>
                            </div>
                        </div>

                        <div className="ticket-text">{textsLang.go}</div>

                        <div className="second-side">
                            <div className="tc-svgs">
                                <Arrow className="tc-arrow"/>
                                <Circle className="tc-dot"/>

                            </div>
                            <div className="counter-title-number">
                                <div className="counter-title">{textsLang.counter}</div>
                                <div className="counter-number">{counterNumber}</div>
                            </div>
                        </div>
                    </div>
                </Transition>
        );
    }
}

export default QueueListItem;

/*
 onAddEndListener = (node, done) => {
        let itemValue = this.props.value;
        TweenMax.to(node, 0.5, {
            autoAlpha: this.props.show === true ? 1 : 0,
            onComplete: done
        });
    };

 <Transition
                    timeout={1500}
                    mountOnEnter={true}
                    unmountOnExit={false}
                    in={this.props.show}

                    addEndListener={this.onAddEndListener} >



const mapStateToProps= (state) => ({
    loginState: state.loginState,
    queueState: state.queueState
});

const mapDispatchToProps =  {
    queueAction
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(QueueListItem);*/

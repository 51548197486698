import {eventConstants, globalConstants} from "../constants";
import {isEqual, detectPrintPermission} from "../constants/utils";
import {cloneDeep} from "lodash";

const initialState = {
    dataReady: false,
    oldFeedData: [],
    items: [],
    printEnabled: false,
    total: -1,
    screen: eventConstants.TICKET_PRINT_SCREEN_SHOW,
    printingSuccess: false,
    ticketPrintResponse: {},
    showPrinting: false
};

const ticketState = (state = initialState, action) => {

    switch (action.type) {

        case eventConstants.POPUP_OPEN_SELECT_TICKET_DONE:
            reducerName(state, action);

            var item = action.payload.item;
            var items = cloneDeep(state.items);
            for (var idx = 0; idx < items.length; idx++) {
                if (items[idx].id === item.id) {
                    let atLeastOneTrue = false;
                    for (var j = 0; j < items[idx].sub.length; j++) {
                        if (items[idx].sub[j].id === item.sub[j].id) {
                            items[idx].sub[j].selected = item.sub[j].selected;
                            if (item.sub[j].selected === true) {
                                atLeastOneTrue = true;
                            }
                        }
                    }
                    items[idx].selected = atLeastOneTrue;
                    break;
                }
            }

            return {
                ...state,
                printEnabled: detectPrintPermission(items),
                items: [...items]
            };

        case eventConstants.DONE_SHOWING_PRINT_SCREEN:
        case eventConstants.CHANGE_LANGUAGE:
            reducerName(state, action);
            var resetArray = cloneDeep(state.items);
            for (let item of resetArray) {
                if (item.selected === true) {
                    item.selected = false;
                    if(item.sub){
                        item.sub.forEach((subItem, subKey) => {
                            if(subItem.selected === true){
                                subItem.selected = false;
                            }
                        });
                    }
                }
            }
            return {
                ...state,
                items: [...resetArray],
                printEnabled: false,
                screen: eventConstants.TICKET_PRINT_SCREEN_SHOW,
                showPrinting: false

            };

        case eventConstants.SUCCESS_PRINT:
            reducerName(state, action);
            return {
                ...state,
                printingSuccess: true,
                ticketPrintResponse: action.payload.response,
                showPrinting: true
            };

        case eventConstants.SELECT_TICKET_BUTTON:
            reducerName(state, action);
            let copyAr = [...state.items];
            copyAr[action.payload.itemKey].selected = copyAr[action.payload.itemKey].selected !== true;

            return {
                ...state,
                items: [...copyAr],
                printEnabled: detectPrintPermission(copyAr)
            };

        case eventConstants.UPDATE_JOBS_CONTENT:
            if(!isEqual(action.payload.response, state.oldFeedData)){
                reducerName(state, action);
                let newData = cloneDeep(action.payload.response);
                return {
                    ...state,
                    items: buildData(action.payload.response, state.items),
                    total: newData.length - 1,
                    oldFeedData: [...newData],
                    dataReady: true
                };
            }
            return state;

        case eventConstants.LOGOUT_SUCCESS:
            reducerName(state, action);

            return {
                ...state,
                ...initialState
            };

        default:
            return state;
    }
};

const reducerName = (state, action) => {
    if (globalConstants.DEV_ENABLE_LOG === true) {
        console.log("--- REDUCER: ticketState     ", action.type);
        console.log("state:  ", state);
        console.log("action:  ", action);
    }
};

const buildData = (pNewData, pOldData) => {

    let processedData = [];
    let idx = 0;
    let i = 0;
    let j = 0;
    let k = 0;
    let alreadySelected = false;
    let found = false;
    let foundSub = false;

    if (pOldData.length === 0) {
        for (idx = 0; idx < pNewData.length; idx++) {
            processedData.push({
                ...pNewData[idx],
                sub: buildSub(pNewData[idx].sub),
                selected: false
            })
        }
    } else {

        for (idx = 0; idx < pNewData.length; idx++) {

            alreadySelected = false;
            found = false;
            for (j = 0; j < pOldData.length; j++) {
                if(pNewData[idx].id === pOldData[j].id){
                    if (pNewData[idx].status !== 0) {
                        alreadySelected = pOldData[j].selected;
                    } else {
                        alreadySelected = false;
                    }
                    found = true;
                    break;
                }
            }

            if(found){
                if(pNewData[idx].sub) {
                    if(pOldData[j].sub){
                        let newSub = cloneDeep(pNewData[idx].sub);
                        let selectedMain = false;
                        foundSub = false;
                        for (k = 0; k < newSub.length; k++) {
                            for (i = 0; i < pOldData[j].sub.length; i++) {
                                if (newSub[k].id === pOldData[j].sub[i].id) {
                                    foundSub = true;
                                    break;
                                }
                            }

                            if(foundSub === true){
                                newSub[k].selected = pOldData[j].sub[i].selected;
                                if(newSub[k].selected === true && newSub[k].status === 0){
                                    newSub[k].selected = false;
                                }
                                if(newSub[k].selected === true){
                                    selectedMain = true;
                                }
                            }
                        }

                        if(selectedMain === true && pNewData[idx].status === 0){
                            selectedMain = false;
                            for (k = 0; k < newSub.length; k++) {
                                newSub[k].selected = false;
                            }
                        }

                        processedData.push({
                            ...pNewData[idx],
                            sub: newSub,
                            selected: selectedMain
                        })

                    } else{
                        processedData.push({
                            ...pNewData[idx],
                            selected: alreadySelected
                        })
                    }

                } else{
                    processedData.push({
                        ...pNewData[idx],
                        selected: alreadySelected
                    })
                }
            } else {
                processedData.push({
                    ...pNewData[idx],
                    selected: alreadySelected
                })
            }
        }
    }

    return processedData;
};

const buildSub = (pNewDataSub, alreadySelected = false, pOldDataSub = null) => {
    if (!pNewDataSub) {
        return null;
    }
    let processedData = [];
    let selected = false;
    for (let idx = 0; idx < pNewDataSub.length; idx++) {
        if(alreadySelected === false){
            processedData.push({
                ...pNewDataSub[idx],
                selected: false
            });
        } else {
            if (pOldDataSub) {
                for (let j = 0; j < pOldDataSub.length; j++) {
                    if(pNewDataSub[idx].id === pOldDataSub[j].id) {
                        selected = false;
                        if (pNewDataSub[idx].status !== 0) {
                            selected = pOldDataSub[j].selected;
                        }
                        processedData.push({
                            ...pNewDataSub[idx],
                            selected: selected
                        });
                        break;
                    }
                }
            } else{
                processedData.push({
                    ...pNewDataSub[idx],
                    selected: false
                });
            }

        }

    }
    return [...processedData];
};

export default ticketState;
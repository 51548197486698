import '../../assets/css/login/login.css';
import React, {Component, Fragment} from 'react';
import FormSetup from "./subc/FormSetup";
import LoginContent from './subc/LoginContent';
import {connect} from "react-redux";
import {eventConstants, globalConstants, operatorPopupConstants} from "../../constants";
import {isEmpty, buildUrlFormat, translateTextAlternate} from "../../constants/utils";
import loginAction from "../../actions/login.action"
import popupLogoutAction from "../../actions/popupLogout.action";
import {Linear, TweenMax} from "gsap";


class Login extends Component {

    constructor(props) {
        super(props);
        this.theComponent = React.createRef();
    }

    componentDidMount() {
        let myLocalStoredUrl = localStorage.getItem(globalConstants.LOCAL_STORAGE_OBJ_PING);

        if(!isEmpty(myLocalStoredUrl)){
            this.props.loginAction({
                type: globalConstants.API,
                success: eventConstants.SUCCESS_PING,
                fail: eventConstants.FAIL_PING,
                url: buildUrlFormat(myLocalStoredUrl) + globalConstants.API_PING
            });
        } else {

            this.props.loginAction({
                type: eventConstants.LAUNCH_SETUP
            });
        }
    }

    renderSetupScreen() {
        let {loginState} = this.props;
        if (loginState.subScreen === eventConstants.LAUNCH_SETUP) {
            return (
                <FormSetup />
            )
        } else {
            return null;
        }
    }

    renderLoginScreen() {
        let {loginState} = this.props;
        if (loginState.subScreen === eventConstants.LAUNCH_LOGIN) {
            return (
                <LoginContent />
            )
        } else {
            return null;
        }
    }

    render() {
        return (
            <Fragment>
                {this.renderSetupScreen()}
                {this.renderLoginScreen()}
                <div className="smartq-popup" ref={this.theComponent}>
                    <div className="popup-overlay"></div>
                    <div className="popup-data">
                        <div className="popup-container">
                            <div className="popup-content">
                                <div className="container">
                                     {this.renderConfirmationPopup()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }



    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.theComponent.current){
            let {loginState } = this.props;

            if(loginState.shown === true){
                this.myTween = TweenMax.to(this.theComponent.current, 0.2,
                    {autoAlpha:1, ease: Linear.easeNone});
            } else {
                this.myTween = TweenMax.to(this.theComponent.current, 0.2,
                    {autoAlpha:0, ease: Linear.easeNone});
            }
        }
    }

    renderConfirmationPopup(){

        let {popupLogoutState, loginState, operatorState} = this.props;
        if(loginState.showErrorPopup === false && loginState.showExitPopup === false){
            return null;
        }

        let { } = this.props;
        let title, body, btnOk, btnCancel;

        let confirmedMode = "";

        switch (loginState.confirmPopupSubType) {

            case "login_error":
                confirmedMode = "login_error";
                break;
            case "exit":
                confirmedMode = "exit";
                break;

            default:
                console.log(" no popup type provided ! ");
        }



        let parentTranslateObj = loginState.language[loginState.currentLang].login;

        title = translateTextAlternate(parentTranslateObj, confirmedMode, "title");
        body = translateTextAlternate(parentTranslateObj, confirmedMode, "body");

        btnOk = translateTextAlternate(parentTranslateObj, confirmedMode, "btn", "ok");
        btnCancel = translateTextAlternate(parentTranslateObj, confirmedMode, "btn", "cancel");

        if(isEmpty(title)){
            title = "";
        }

        if(isEmpty(body)){
            body = "";
        }

        if(isEmpty(btnOk)){
            btnOk = "";
        }

        if(isEmpty(btnCancel)){
            btnCancel = "";
        }

        return (
            <div className="inside-content">
                <div className="popup-confirm-cancel">
                    <div className="title">{title}</div>
                    <div className="guidance">{body}</div>
                    <div className="action-buttons">
                        {this.renderOkButton(btnOk)}
                        {this.renderCancelButton(btnCancel)}
                    </div>
                </div>
            </div>
        )

    }

    renderOkButton(pValue) {
        let{loginState} = this.props;

        if (!isEmpty(pValue)) {
            return (
                <button className="confirm-action" type="button" ref={this.buttonConfirm}
                        disabled={!loginState.shown}
                        onClick={(e) => this.buttonPressConfirm(e)}>
                    <span className="texts">{pValue}</span>
                </button>
            )
        } else {
            return null;
        }
    }

    buttonPressConfirm(e) {
        if (e) e.preventDefault();

        let {popupLogoutState, loginAction, loginState} = this.props;
        let popupPayload = popupLogoutState.popupPayload;

        console.log(loginState);
        if(loginState.showExitPopup === true){
            loginAction({
                type: eventConstants.POPUP_ACTION_EXIT_SUCCESS
            });
        } else{
            loginAction({
                type: eventConstants.HIDE_LOGIN_ERR_POPUP
            });
        }

    }

    buttonPressCancel(e) {
        if (e) e.preventDefault();

        let {loginAction} = this.props;
        loginAction({
            type: eventConstants.HIDE_LOGIN_POPUP
        });
    }

    renderCancelButton(pValue) {
        let{loginState} = this.props;

        if (!isEmpty(pValue)) {
            return (
                <button className="cancel-action" type="button" ref={this.buttonCancel}
                        disabled={!loginState.shown}
                        onClick={(e) => this.buttonPressCancel(e)}>
                    <span className="texts">{pValue}</span>
                </button>
            )
        } else {
            return null;
        }
    }

}


const mapStateToProps = (state) => ({
    loginState: state.loginState,
    popupLogoutState: state.popupLogoutState
});

const mapDispatchToProps = {
    loginAction,
    popupLogoutAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
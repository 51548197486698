import '../../assets/css/login/login.css';
import React, { Component } from 'react';
import { connect} from "react-redux";
import {ticketPrintCall} from "../../actions";
import {eventConstants, globalData} from "../../constants";
import {ReactComponent as CheckMark} from "../../assets/img/check.svg";


class PinInputItem extends Component {

    constructor(props){
        super(props);
        this.myButton = React.createRef();
        this.value = "";
    }

    buttonPress(e){
        if(e) e.preventDefault();

        this.props.ticketPrintCall({
            type: eventConstants.PIN_NEW_CHAR_INPUT,
            value: this.value
        });


        if(this.value !== "<"){

        } else {

        }

    }


    render() {
        this.value = this.props.value;
        if(this.value === "10"){
            this.value = "DEL";
        }
        return (
            <button  ref={this.myButton}
                     onClick={(e) => this.buttonPress(e)}
                     className="button_numpad"
                     type="button">
                <span className="texts_numpad">{this.value}</span>
            </button>
        );
    }
}


const mapStateToProps = (state) => ({

});

const mapDispatchToProps =  {
    ticketPrintCall
};


export default connect(mapStateToProps, mapDispatchToProps)(PinInputItem);

import '../../assets/css/login/login.css';
import React, { Component } from 'react';
import { connect} from "react-redux";
import {adCall, ticketPrintCall} from "../../actions";
import {eventConstants, globalConstants, globalData} from "../../constants";
import {Linear, Power4, TweenMax} from "gsap";
import {ReactComponent as RatingIcon} from "../../assets/img/rating.svg";
import adAction from "../../actions/adAction";

class RatingButton extends Component {

    constructor(props){
        super(props);
        this.theComponent = React.createRef();
    }

    buttonPressed(e){
        if(e) e.preventDefault();
        let {adAction, loginState, adStateDetect} = this.props;

        adAction({
            type: globalConstants.API,
            success: eventConstants.RATE_NOW_SUCCESS,
            fail: eventConstants.API_LIGHT_FAIL,
            url: loginState.config.ticket_update + loginState.loginAuth + "&ticket=" + adStateDetect.ticket + "&stars=" + this.props.rateScore
        });
    }

    render() {
        let {adStateDetect} = this.props;
        return (
            <button type="button" ref={this.theComponent}
                    onClick={(e) => this.buttonPressed(e)}
                    disabled={adStateDetect.enableRateButton===true?"":"disabled"}
            >
                <span className="icon">
                    <RatingIcon/>
                </span>
            </button>
        );
    }
}

const mapStateToProps = (state) => ({
    adStateDetect: state.adStateDetect,
    loginState: state.loginState
});

const mapDispatchToProps =  {
    adAction
};


export default connect(mapStateToProps, mapDispatchToProps)(RatingButton);

import {eventConstants, globalConstants} from "../constants";


const ticketAction = (payload) => {
    switch (payload.type) {
        case eventConstants.POPUP_OPEN_PRINT_TICKET_BACK:
            componentActionName(payload);
            return {
                type: payload.type,
                payload
            };

        case eventConstants.POPUP_OPEN_PRINT_TICKET:
            componentActionName(payload);
            return {
                type: payload.type,
                payload
            };

        case eventConstants.POPUP_OPEN_SELECT_TICKET:
            componentActionName(payload);
            return {
                type: payload.type,
                payload
            };

        case eventConstants.DONE_SHOWING_PRINT_SCREEN:
            componentActionName(payload);
            return {
                type: payload.type,
                payload
            };

        case globalConstants.API:
            componentActionName(payload);
            return {
                type: payload.type,
                payload
            };

        case eventConstants.SELECT_TICKET_BUTTON:
            componentActionName(payload);
            return {
                type: payload.type,
                payload
            };


        case eventConstants.TIMER_STOP:
            componentActionName(payload);
            return {
                type: payload.type,
                payload
            };

        case eventConstants.TIMER_START:
            componentActionName(payload);
            return {
                type: payload.type,
                payload
            };

        default:
            return {
                type: payload.type,
                payload
            }
    }
};


const componentActionName = (payload) => {
    if (globalConstants.DEV_ENABLE_LOG === true) {
        console.log("--- ACTION: ticketAction     ", payload.type);
        console.log("payload:  ", payload);
    }
};


export default ticketAction;
import {eventConstants, globalConstants} from "../constants";


const newsfeedAction = (payload) => {
    switch (payload.type) {

        case eventConstants.NEWS_TICKER_ANIM_COMPLETE:
            componentActionName(payload);
            return {
                type: payload.type,
                payload
            };

        case eventConstants.START_NEWS_TICKER_ANIMS:
            componentActionName(payload);
            return {
                type: payload.type,
                payload
            };

        case eventConstants.TIMER_STOP:
            componentActionName(payload);
            return {
                type: payload.type,
                payload
            };


        case eventConstants.TIMER_START:
            componentActionName(payload);
            return {
                type: payload.type,
                payload
            };

        default:
            return {
                type: payload.type,
                payload
            }
    }
};


const componentActionName = (payload) => {
    if (globalConstants.DEV_ENABLE_LOG === true) {
        console.log("--- ACTION: newsfeedAction     ", payload.type);
        console.log("payload:  ", payload);
    }
};


export default newsfeedAction;
import '../../assets/css/operator/operator.css';
import React, {Component, Fragment} from 'react';
import { connect} from "react-redux";
import {eventConstants, operatorPopupConstants} from "../../constants";
import operatorAction from "../../actions/operator.action";
import loginAction from "../../actions/login.action";
import {ReactComponent as SettingsIcon} from "../../assets/img/settings.svg";
import {translateModuleText} from "../../constants/utils";

class TopArea extends Component {

    constructor(props){
        super(props);
    }

    componentDidMount() {
        let {operatorAction} = this.props;
        operatorAction({
            type: eventConstants.TIMER_START,
            timerName: "operator_clock",
            timerInterval: 60000,
            secondCall:{
                callType: eventConstants.EXECUTE_EVENT,
                success: eventConstants.UPDATE_NEWS_FEED_CLOCK
            }
        });
    }

    componentWillUnmount() {
        let {operatorAction} = this.props;
        operatorAction({
            type: eventConstants.TIMER_STOP,
            timerName: "operator_clock"
        });
    }

    buttonPressSettings(e){
        if(e) e.preventDefault();
        let {operatorAction} = this.props;
        operatorAction({
            type: operatorPopupConstants.SHOW_SETTINGS_POPUP
        })
    }
    render() {
        let {loginState, clockState, operatorState} = this.props;

        let ticketText = "";

        if(operatorState.ticketNumber === -1){
            ticketText = translateModuleText(loginState, "noticket");
        } else{
            ticketText = translateModuleText(loginState, "ticket").replace("{TICKET}", operatorState.ticketNumber);
        }

        return (
            <Fragment>
                <div className="logo-time">
                    <div className="logo">
                        <img src={loginState.config.logo.square} alt="" className="smartq-logo" id="smartq-logo"/>
                    </div>
                    <div className="operator-intro">
                        <h1 className="title"><span>{ticketText}</span></h1>
                    </div>
                    <div className="time">
                        <div className="popup-trigger">
                            <SettingsIcon onClick={(e) => this.buttonPressSettings(e)}/>
                        </div>
                        <div className="time-content">
                            <span>{clockState.time}</span>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    loginState: state.loginState,
    operatorState: state.operatorState,
    clockState: state.clockState
});

const mapDispatchToProps =  {
    operatorAction,
    loginAction
};

export default connect(mapStateToProps, mapDispatchToProps)(TopArea);
import {eventConstants, globalConstants, globalData, serverFields} from "../constants";
import {getLocalUserData, buildUrlFormat, isEmpty, isEqual} from "../constants/utils"
import {cloneDeep} from "lodash";

const initialState = {
    oldFeedData: [],
    items: [],
    total: -1,
    currentTextIdx: -1,
    wrapperWidth: 0,
    permitStart: false,
    config: {
        mode: "CONTINUOUS"
    }
};

const newsFeedState = (state = initialState, action) => {

    switch(action.type) {
        case eventConstants.NEWS_TICKER_ANIM_COMPLETE:
            reducerName(state, action);
            return {
                ...state,
                currentTextIdx: state.currentTextIdx === state.total ? 0 : state.currentTextIdx + 1
            };

        case eventConstants.START_NEWS_TICKER_ANIMS:
            reducerName(state, action);
            return {
                ...state,
                currentTextIdx: 0,
                wrapperWidth: action.payload.wrapperWidth,
                permitStart: false
            };

        case eventConstants.UPDATE_NEWS_CONTENT:
            if(!isEqual(action.payload.response, state.oldFeedData)){
                reducerName(state, action);
                let newData = cloneDeep(action.payload.response);
              
                if(action.payload.config.continuous_scroll.speed === 0){
                    action.payload.config.continuous_scroll.speed = 5
                }
                return {
                    ...state,
                    oldFeedData: [...newData],
                    items: [...newData],
                    total: newData.length - 1,
                    config: {...action.payload.config},
                    permitStart: true
                };
            }
            return state;
        case eventConstants.LOGOUT_SUCCESS:
            reducerName(state, action);

            return{
                ...state,
                ...initialState
            };
        default:
            return state;
    }
};

const reducerName = (state, action) => {
    if(globalConstants.DEV_ENABLE_LOG === true){
        console.log("--- REDUCER: newsFeedState     ", action.type);
        console.log("state:  ", state);
        console.log("action:  ", action);
    }
};

export default newsFeedState;
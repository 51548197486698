import {eventConstants, globalConstants} from "../constants";
import * as utils from "../constants/utils";
import {cloneDeep} from "lodash";



const initialState = {
    adItemsAr: [],
    current: -1,
    previous: -1,
    ticketData: {},


    callInfo: false,
    callTicketRefreshStart: true,
    enableRateButton: false,

    ticketCounterOldData: {},
    ticketStatus: -1,
    ticketMsg: "",
    ticket: -1,
    showTicketPanel: false,

    rateDataPresent: -1,
    rateTicket: -1,
    showRating: false,
    cancelInfoCall: false
};

const adStateDetect = (state = initialState, action) => {
    switch (action.type) {
        case eventConstants.RATE_NOW_SUCCESS:
        case eventConstants.HIDE_RATING_SCREEN:
            reducerName(state, action);
            return {
                ...state,
                enableRateButton: false,
                rateTicket: -10
            };

        case eventConstants.SHOW_RATING_SCREEN:
            reducerName(state, action);
            return {
                ...state,
                ticket: action.payload.ticket,
                enableRateButton: true,
                rateTicket: action.payload.ticket
            };

        case eventConstants.AD_RATING_DATA_CALL_SUCCESS:
            if (!utils.isEqual(action.payload.response, state.ticketCounterOldData)) {
                reducerName(state, action);
                let newData = cloneDeep(action.payload.response);
                let rateTicket = state.ticket;
                let ticket = state.ticket;

                if(newData.ticket){
                    rateTicket = newData.ticket;
                    ticket = newData.ticket;
                }

                return {
                    ...state,
                    ticketCounterOldData: cloneDeep(action.payload.response),
                    rateTicket,
                    ticket,
                    showRating: false
                }
            }
            return state;




        case eventConstants.AD_TICKET_CALL_SUCCESS:
            if (!utils.isEqual(action.payload.response, state.ticketCounterOldData)) {
                reducerName(state, action);
                let newData = cloneDeep(action.payload.response);
                var ticket = state.ticketNo;
                var ticketStatus = state.ticketStatus;
                var ticketMsg = "";
                var showTicketPanel = state.showTicketPanel;

                if (newData.closed === 0) {
                    if (newData.ticket) {
                        ticket = newData.ticket;
                    } else {
                        ticketStatus = -1;
                    }

                    if (newData.status) {
                        ticketStatus = newData.status;
                    } else {
                        ticketStatus = -1;
                    }

                    if (ticketStatus === 2 || ticketStatus === 3) {
                        ticketMsg = "NOW CALLING";
                        showTicketPanel = true;
                    } else if (ticketStatus === 4) {
                        ticketMsg = "NOW SERVING";
                        showTicketPanel = true;
                    } else {
                        showTicketPanel = false;
                    }
                } else {
                    ticketMsg = "CLOSED";
                    showTicketPanel = true;
                }

                return {
                    ...state,
                    ticketCounterOldData: cloneDeep(action.payload.response),
                    ticket,
                    ticketStatus,
                    ticketMsg,
                    showTicketPanel
                };
            }
            return state;

        case eventConstants.SHOW_NEXT_MEDIA_ITEM:
            var prevIdx = state.current;
            var nextIdx = state.current + 1;
            if (!state.adItemsAr[nextIdx]) {
                nextIdx = 0;
            }

            return {
                ...state,
                current: nextIdx,
                previous: prevIdx
            };


        case eventConstants.AD_SERVER_CALL_SUCCESS:
            if(!utils.isEqual(action.payload.response, state.adItemsAr)){
                reducerName(state, action);

                let adItemsCopy = cloneDeep(action.payload.response);

                var prevIdx = state.current;
                var nextIdx = state.current + 1;

                if(prevIdx !== -1){
                    prevIdx = state.current
                }
                nextIdx = 0;


                return {
                    ...state,
                    adItemsAr: [...adItemsCopy],
                    current: nextIdx,
                    previous: prevIdx
                }
            } else {
                return state;
            }

        case eventConstants.LOGOUT_SUCCESS:
            return {
                ...state,
                ...initialState
            };
        default:
            return state;
    }
};

const reducerName = (state, action) => {
    if(globalConstants.DEV_ENABLE_LOG === true){
        console.log("--- REDUCER: adStateDetect     ", action.type);
        console.log("state:  ", state);
        console.log("action:  ", action);
    }
};


export default adStateDetect;